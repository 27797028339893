.informationBlock,
.informationEditBlock {
  width: 100%;
  max-width: 60.8rem;
  .title {
    border-bottom: 0.1rem solid var(--border-color, $border-color);
    padding-bottom: 1.6rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin-bottom: 0;
    }
  }
  .contentItem {
    @apply flex flex-col justify-center md:flex-row md:justify-between;
    border-bottom: 0.1rem solid var(--hairline-color, $hairline-color);
    margin-bottom: 1.2rem;
    position: relative;
    .missing-field {
      cursor: pointer;
      width: 2rem;
      margin-bottom: 1.2rem;
      position: absolute;
      right: 0;
      text-align: center;
      @media ($bp-md) {
        left: -3.6rem;
      }
    }
    small {
      margin-bottom: 0.4rem;
    }
    p {
      min-height: 2rem;
      margin-bottom: 1.2rem;
    }
  }
  @media ($bp-md) {
    .contentItem,
    .title {
      margin-bottom: 2.4rem;
    }
    .title {
      padding-bottom: 2.4rem;
    }
    .contentItem {
      small,
      p {
        margin-bottom: 2.4rem;
      }
    }
  }
  :last-child {
    margin-bottom: 0;
  }
}

.informationEditBlock {
  .title {
    margin-bottom: 2.4rem;
  }
  .inputItem {
    @apply flex flex-col;
    margin-bottom: 1.6rem;
    &.incomplete {
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      select {
        background: rgba(248, 230, 149, 0.6);
      }
    }
    .help-btn {
      height: 2.4rem;
      width: 2.4rem;
      margin: -0.2rem 0;
    }
    .labelWrapper {
      margin-bottom: 0.8rem;
    }
    .sliderText {
      @apply flex justify-between;
      margin-bottom: 1.4rem;
      * {
        margin-bottom: 0;
      }
    }
    .sliderProgressContainer {
      @apply flex items-center;
      height: 2.4rem; //height of thumb
      margin-top: -2.4rem;
      transform: translateY(2.4rem);
      width: 100%;
      .sliderProgressBar {
        @include track;
        background: var(--border-color, $border-color);
        border-radius: 0.5rem;
        overflow: hidden;
        .sliderProgress {
          height: 100%;
          background: var(--primary-color, $primary-color);
        }
      }
    }
    .minMax {
      margin-top: 0.6rem;
    }
  }
}
