.fafsa-css-logo {
  width: 70%;
  min-width: 14rem;
  height: 100%;
  min-height: 8rem;
  max-height: 12rem;
  position: relative;
  > div {
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
  }
}
