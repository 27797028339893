.errorModalContainer {
  @apply flex justify-center;
  align-items: center;
  background: rgba(var(--tertiary-color-rgb, $tertiary-color-rgb), 0.8);
  z-index: 100;
  .modal {
    @apply flex flex-col items-center justify-center text-center;
    width: auto;
    max-width: min(50rem, 90%);
    min-width: 28rem;
    height: auto;
    max-height: 100%;
    background: var(--background-color, $background-color);
    border-radius: 0.8rem;
    .modal-scroll {
      width: 100%;
      padding: 3.2rem;
      overflow: scroll;
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        width: 0; /* Remove scrollbar space */
      }
    }
  }
  small {
    color: var(--error-color, $error-color);
  }
  .hideErrorBtn {
    width: 100%;
    max-width: 15.9rem;
    height: 5.6rem;
  }
}
