.graph {
  padding-top: 2.4rem;
  padding-bottom: 6.4rem;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0; /* Remove scrollbar space */
  }
  @media ($bp-lg) {
    padding-top: 0.8rem;
  }
  .major-graph {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding-left: 5.6rem;
  }
  .graph-line {
    height: 0;
    margin-bottom: 6.5rem;
    width: 100%;
  }
  .y-axis-label {
    width: 2rem;
  }
  .bar-outer {
    width: 11rem;
    transition: height 0.7s ease;
    .bar-inner {
      width: 6.4rem;
      background-color: var(--disabled-color, $disabled-color);
      &.highlight {
        background-color: var(--primary-color, $primary-color);
      }
    }
    .x-axis-label {
      top: 1.95rem;
    }
  }
}
