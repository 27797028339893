#school_more_info {
  padding-bottom: 4.8rem;
  border-radius: 0.8rem;
  .container {
    padding: 0 2.4rem;
  }
  .graph-container {
  }
  .name-section {
    padding: 2.4rem;
    @media ($bp-lg) {
      padding: 4.8rem;
    }
    .schoolLogo {
      @media ($bp-lg) {
        width: 7.2rem;
        min-width: 7.2rem;
        height: 7.2rem;
      }
    }
  }
  .content-section {
    margin-bottom: 4.8rem;
  }
  li {
    height: 6.8rem;
    margin-left: 0;
    margin-bottom: 0;
    &:nth-last-of-type(odd) {
      background-color: var(--contrast-color2, $contrast-color2);
    }
    h6 {
      color: var(--label-text-color, $label-text-color);
      margin-bottom: 0;
    }
  }
}
