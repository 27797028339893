.card {
  .addSchoolBtn {
    width: 15.6rem;
    height: 4.8rem;
    margin: 1.6rem;
  }
  .pinSticker {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    color: var(--border-color, $border-color);
    background: var(--secondary-color, $secondary-color);
    margin-right: 0.8rem;
  }
  .topHeading {
    .h4 {
      margin-bottom: 0.8rem;
      display: block;
    }
    .h6 {
      margin-left: 1.2rem;
      margin-bottom: 0;
    }
  }
  .forbesRanking {
    margin-bottom: 2.2rem;
  }
  .moreInfoCell {
    margin-bottom: 1.6rem;
    small {
      margin-bottom: 0.4rem;
    }
    p {
      margin-bottom: 0;
    }
    &:nth-of-type(odd) {
      width: 60%;
    }
    &:nth-of-type(even) {
      width: 40%;
    }
    @media ($bp-md) {
      //handle desktop layout of dropdown part of advanced school card
      &:nth-child(1),
      &:nth-child(5) {
        width: 33.3333%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(7) {
        width: 25%;
      }
      &:nth-child(4),
      &:nth-child(8) {
        width: 16.6666%;
        padding-left: 2.4rem;
      }
      &:nth-last-child(4) {
        margin-bottom: 0;
      }
      &:nth-last-child(3) {
        margin-bottom: 0;
      }
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .divider {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .price {
    small {
      color: var(--label-text-color, $label-text-color);
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}
