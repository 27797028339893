.dashboardContainer {
  top: 0;
  background-color: var(--secondary-color, $secondary-color);

  @media ($bp-lg) {
    background-color: var(--tertiary-color, $tertiary-color);
  }

  .dashboardInfo {
    min-height: 8rem;
    color: var(--highlight-text-color, $highlight-text-color);
    position: relative;

    .bannerContainer {
      margin-bottom: 2rem;

      .bannerRight {
        border-radius: 0 .8rem .8rem 0;
      }

      .registerBtnContainer {
        height: inherit;

        .registerBtn {
          margin-left: 3rem;
          margin-right: 4.8rem;
        }

        button {
          padding: 0.6rem;
          right: inherit;
          height: inherit;

          span {
            @include h6;
          }

          &.highlight {
            &:hover {
              color: var(--tertiary-color, $tertiary-color);
              background: var(--secondary-color-invert,
                  $secondary-color-invert );
              border-radius: 0.8rem;
            }
          }
        }
      }

      .imgContainer {
        position: relative;
      }

      .closeButton {
        // position: absolute;
        // top: -.3rem;
        // right: 2rem;
        width: 3rem;
        height: 3rem;
        color: #1d85be;
      }
    }

    .banner {
      border-radius: .8rem 0 0 .8rem;
    }

    .nameplate {
      button {
        margin-top: 0.8rem;
        padding: 0.8rem;

        .icon {
          color: var(--primary-color, $primary-color);
        }
      }
    }

    .efcContainer {
      overflow: hidden;
      // height: 40rem; //was 23.2rem
      transition: all 0.35s ease;

      @media ($bp-lg) {
        // height: 11.8rem;
        overflow: visible;

        .sai-label {
          display: flex;
          align-items: start;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          padding-left: 3.2rem;
          padding-right: 3.2rem;
        }

        small {
          color: var(--quaternary-color, $quaternary-color);
          margin-bottom: 0.6rem;
          white-space: nowrap;
          width: min-content;
          flex-grow: 1;
          align-items: center;
          display: flex;
        }

        .spacer {
          min-width: 0.1rem;
          height: 5.6rem;
          background: rgba(var(--border-color, $border-color), 0.3);
        }

        .efcMenu {
          position: absolute;
          right: 0;

          ul {
            position: absolute;
            margin-top: 1.2rem;
            transform: translateX(-100%);
            border-radius: 0.4rem;
            border: 0.1rem solid var(--primary-color, $primary-color);
            background: var(--background-color, $background-color);
            z-index: 98; //1 less than modal

            li {
              width: 100%;
              list-style-type: none;
              border-bottom: 0.1rem solid var(--primary-color, $primary-color);
              padding-top: 0.8rem;
              padding-bottom: 0.8rem;
              padding-left: 1.6rem;
              padding-right: 1.6rem;
              margin: 0;

              .editBtn {
                color: var(--primary-color, $primary-color);
                margin: 0;
                padding: 0;
              }
            }

            li:last-child {
              border-bottom: none;
            }
          }
        }

        .dots {
          width: 3.8rem;
          height: 6.2rem;
          padding: 1.6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          >* {
            width: 0.6rem;
            height: 0.6rem;
            background: var(--dots-color, $dots-color);
          }

          &:hover {
            >* {
              background: var(--dots-hover-color, $dots-hover-color);
            }
          }
        }

        .invertDots {
          >* {
            background: var(--quaternary-color, $quaternary-color);
          }
        }
      }

      span {
        color: var(--secondary-color-invert, $secondary-color-invert);
      }

      .efcInfo {
        padding-top: 2.4rem;
        padding-bottom: 2rem;

        >* {
          margin-bottom: 2.4rem;
        }

        .editBtn {
          color: var(--primary-color, $primary-color);
        }

        .efcData {
          >div {
            width: 33%;
            min-width: 110px;
          }

          small {
            color: var(--quaternary-color, $quaternary-color);
            margin-bottom: 0.4rem;
            height: 3em;
            display: flex;
            flex-direction: column;
            justify-content: end;
          }

          span {
            // height: 3rem;
          }

          .spacer {
            height: 4.4rem;
            width: 1px;
            background: rgba(var(--border-color, $border-color), 0.2);
            margin-left: 1.6rem;
            margin-right: 1.6rem;
          }
        }
      }

      &.collapsed {
        height: 0;
      }
    }

    .efcText {
      .mobileEfcTextContainer {
        width: 70%;
        max-width: 30rem;
      }

      small {
        color: var(--secondary-color-invert, $secondary-color-invert);
      }

      .playButton {
        color: var(--secondary-color-invert, $secondary-color-invert);
        border-radius: 10rem;
        overflow: hidden;

        :hover {
          color: var(--secondary-color, $secondary-color);
          background: radial-gradient(var(--secondary-color-invert, $secondary-color-invert) 58%,
              transparent 59%);
        }
      }

      .learnMoreBtn {
        max-height: 4rem;

        button {
          padding: 0.6rem;

          span {
            @include h6;
          }

          &.highlight {
            &:hover {
              color: var(--tertiary-color, $tertiary-color);
              background: var(--secondary-color-invert,
                  $secondary-color-invert );
              border-radius: 0.8rem;
            }
          }
        }
      }
    }

    .schoolSearchContainer {
      width: 100%;
      position: absolute;
      bottom: -4.4rem;

      .schoolSearchBtn {
        width: 100%;
        height: 5.6rem;

        @media ($bp-md) {
          max-width: 29.2rem;
        }
      }
    }
  }
}