.upgradePlanContainer {
  width: 100%;
  background-color: var(--background-color, $background-color);
  .headerSection {
    background-color: transparent;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
    .logo {
      @apply rounded-full m-auto block;
      height: 2.8rem;
      width: 2.8rem;
      color: var(--primary-color, $primary-color);
    }
  }
  @media ($bp-lg) {
    padding-top: 8rem;
  }
  .gridContainer {
    display: grid; //wrapping containers with display: grid with another container with display: grid fixes safari height bug.
  }
  .cardsContainer {
    padding-top: 1.6rem;
    padding-bottom: 4.8rem;
    background-color: transparent;
    max-width: 100%;
    padding-left: calc(
      50vw - 15rem
    ); //Keep cards centered on screen when scroll snaps
    padding-right: calc(
      50vw - 15rem
    ); //Keep cards centered on screen when scroll snaps
    scroll-margin-right: 15rem;
    display: grid;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
      width: 0; /* Remove scrollbar space */
    }
    grid-gap: 3.2rem;
    grid-auto-columns: 30rem; //fixed width cards
    scroll-padding: 1.6rem;
    //On safari, horizontal scrolling ignores end-side padding so we must add before and after for mobile.
    &::before {
      content: "";
    }
    &::after {
      content: "";
    }
    @media ($bp-lg) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    .planCard {
      scroll-snap-align: center;
      padding: 2.4rem;
      position: relative;
      height: 100%;
      background-color: var(--card-color, $card-color);
      border-radius: 0.8rem;
      overflow: visible;
      &.recommended {
        background-color: var(--secondary-color, $secondary-color);
        color: var(--highlight-text-color, $highlight-text-color);
      }
      .header-list {
        li {
          margin-left: 1.6rem;
          span {
            left: -0.4rem;
          }
        }
        > :last-child {
          margin-bottom: 0;
        }
      }
      .checklist {
        li {
          &:before {
            background-color: var(--primary-color, $primary-color);
          }
          &.not {
            &:before {
              background-color: var(--error-color, $error-color);
            }
          }
        }
      }
      button {
        bottom: 2.4rem;
        width: 23.4rem;
        height: 4.8rem;
        margin: auto;
      }
      hr {
        background-color: rgba(191, 196, 205, 0.2);
        margin: 1.6rem 0;
      }

      .recommendedTag {
        position: absolute;
        top: -1rem;
        background-color: var(--recommended-tag-color, $recommended-tag-color);
        z-index: 100;
        border-radius: 1rem;
        padding: 0.3rem 1.2rem;
      }

      .planOptions {
        margin-top: 2rem;
      }

      input[type="radio"] {
        opacity: 1;
        display: initial;
        width: 14px;
        height: 14px;
        margin: 0 1rem 0.5rem 1rem;
      }

      input[type="radio"]:checked:after {
        width: 105%;
        height: 105%;
        border-radius: 100%;
        background-color: var(--primary-color, $primary-color);
        content: "";
        display: inline-block;
        visibility: visible;
        border: 2.5px solid var(--highlight-text-color, $highlight-text-color);
        position: relative;
        top: -0.1rem;
        left: -0.05rem;
      }
    }
  }
}
