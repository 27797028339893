.schoolContainer {
  position: relative;
  .topBackground {
    height: 30.2rem;
    position: absolute;
    background: var(--secondary-color, $secondary-color);
    z-index: -1;
    @media ($bp-lg) {
      background: var(--tertiary-color, $tertiary-color);
    }
  }
  .content {
    max-width: 92.4rem;
  }
  .nav {
    position: relative;
    height: 7.2rem;
    color: var(--highlight-text-color, $highlight-text-color);
    background: var(--secondary-color, $secondary-color);
    .emptySpace {
      width: 2.4rem;
    }
  }
  .schoolName {
    position: relative;
    color: var(--highlight-text-color, $highlight-text-color);
    .schoolLogo {
      min-width: 7.2rem;
      width: 7.2rem;
      height: 7.2rem;
      margin-right: 1.6rem;
    }
    h3 {
      margin-bottom: 0;
      @media ($bp-lg) {
        @include h1;
      }
    }
  }
  .financialDetails {
    @include lightShadow;
    background: var(--card-color, $card-color);
    border: 0.1rem solid var(--border-color, $border-color);
    border-radius: 0.8rem;
    max-width: 37.2rem;
  }
  .applyingContainer {
    background: var(--border-color, $border-color);
    height: 8rem;
    margin-bottom: 4.8rem;
    .toggle {
      margin-left: 1.6rem;
      div {
        padding: 0.4rem;
        position: absolute;
        top: 0.2rem;
        transition: opacity 0.4s;
      }
      .yes {
        left: 1rem;
        opacity: 0;
      }
      .no {
        right: 1rem;
        opacity: 1;
      }
      input:checked ~ .slider {
        .yes {
          opacity: 1;
        }
        .no {
          opacity: 0;
        }
      }
    }
  }
  .schoolInfo {
    margin-bottom: 4.8rem;
    .drawers {
      > :last-child {
        border-bottom: 1px solid var(--border-color, $border-color);
      }
    }
    h2 {
      margin-bottom: 2rem;
    }
  }
  .scholarships {
    .text {
      .title {
        width: 80%;
      }
      .awards {
        @media ($bp-lg) {
          max-width: 24.2rem;
        }
        .awardsTotal {
          margin-bottom: 0.4rem;
        }
      }
      button {
        height: 3.2rem;
        width: 8rem;
        @media ($bp-lg) {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
  .awardsColumns {
    > :nth-child(1) {
      width: 42%;
    }
    > :nth-child(2) {
      width: 23%;
    }
    > :nth-child(3) {
      width: 22%;
    }
    > :nth-child(4) {
      width: 13%;
    }
  }
  .scholarshipCards {
    margin-bottom: 4.8rem;
    .typeShort {
      //TODO: refactor with school_card.scss
      @media ($bp-md) {
        padding: 2.4rem;
      }
      .scholarshipCard {
        .checkbox {
          position: absolute;
          right: 0;
          @media ($bp-md) {
            position: relative;
            right: unset;
          }
        }
        .scores {
          @media ($bp-md) {
            > * {
              margin-right: 1.5rem;
            }
          }
        }
      }
      .description {
        max-width: 52.9rem;
      }
    }
    > * {
      margin-bottom: 1.2rem;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
}
