.how-to-pay-container {
  .back-btn {
    margin-top: 4.8rem;
    @media ($bp-sm) {
      margin-top: 0.8rem;
    }
  }
  .color-red {
    @include colorRed;
  }
}

.how-to-pay-view {
  .how-to-pay-card {
    @include lightShadow;
    background: var(--contrast-color, $contrast-color);
    border: 0.1rem solid var(--border-color, $border-color);
    border-radius: 0.8rem;
    overflow: hidden;
    .how-to-pay-summary {
      background: var(--contrast-color, $contrast-color);
      border-bottom: 0.1rem solid var(--border-color, $border-color);
      height: 100%;
      padding: 0.8rem;
      .school-summary {
        background: var(--background-color, $background-color);
        width: 100%;
        height: 100%;
        .school-heading {
          position: relative;
          .schoolLogo {
            min-width: 5.6rem;
            width: 5.6rem;
            height: 5.6rem;
            margin-bottom: 0.8rem;
            mix-blend-mode: var(
              --mix-mode
            ); //no default as default should be no blending.
          }
          .year-one-loans {
            @include colorRed;
            margin-bottom: 0.4rem;
          }
        }
        .summary-panel {
          .school-values {
            .value-header {
              @include h6;
              @media ($bp-md) {
                @include h3;
              }
            }
            > * {
              padding-right: 2.4rem;
            }
            > :last-child {
              padding-right: 0rem;
            }
          }
        }
        .control-panel {
          top: 0;
          right: 0;
          position: absolute;
          .save {
            color: var(--primary-color, $primary-color);
            &:disabled {
              color: var(--disabled-color, $disabled-color);
            }
          }
        }
      }
    }
    .how-to-pay-breakdown {
      background: var(--background-color, $background-color);
      margin: 0.8rem;
      .breakdown-header {
        background: var(--hairline-color, $hairline-color);
        border-bottom: 0.1rem solid var(--border-color, $border-color);
        height: 4.8rem;
        .import-offer-btn {
          // background: var(--background-color, $background-color);
        }
        .column-header {
          width: 12rem;
          @include bodySmall;
          @media ($bp-sm) {
            @include bodyRegular;
            width: 20rem;
          }
        }
      }
      .breakdown-table {
        @include bodySmall;
        > :first-child {
          padding-top: 0.4rem;
          @media ($bp-sm) {
            padding-top: 0.8rem;
          }
        }
        > :last-child {
          padding-bottom: 0.8rem;
          @media ($bp-sm) {
            padding-bottom: 1.6rem;
          }
        }
        .accordion {
          > :nth-child(odd) {
            background: var(--contrast-color, $contrast-color);
          }
        }
        @media ($bp-sm) {
          @include bodyRegular;
        }
        .breakdown-row {
          color: var(--body-text-color, $body-text-color);
          .progress-btn {
            right: 0;
            height: 2.8rem;
            width: 2.8rem;
            &.get-started {
              padding: 0.3rem;
              .double-arrow {
                > * {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  padding: 0.5rem;
                }
                > :first-child {
                  left: -10%;
                }
                > :last-child {
                  left: 15%;
                }
              }
            }
            &.in-progress {
            }
            &.done {
              padding: 0.3rem;
            }
          }
          .questionnaire-btn {
            width: 9.2rem;
            height: 4rem;
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;
            @include bodySmall;
            @media ($bp-sm) {
              @include bodyRegular;
              width: 17rem;
            }
          }
          .breakdown-inputs {
            > :nth-child(2) {
              margin-left: 0.8rem;
              @media ($bp-sm) {
                margin-left: 1.6rem;
              }
            }
            .breakdown-value {
              position: relative;
              input {
                width: 12rem;
                height: 4.8rem;
                border: 0.1rem solid var(--border-color, $border-color);
                border-radius: 0.4rem;
                &.negative {
                  background: $cap-yellow;
                }
                @include bodySmall;
                @media ($bp-sm) {
                  @include bodyRegular;
                  width: 20rem;
                }
              }
              .value-type {
                color: var(--primary-color, $primary-color);
                position: absolute;
                bottom: 0;
                right: 0;
                // padding: 1.4rem 1.2rem;
                padding: 0 1.2rem;
                height: 4.8rem;
                display: flex;
                align-items: center;
                .logo {
                  max-width: 2.4rem;
                  max-height: 2.4rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
