.how-to-borrow {
  .card {
    width: 29.2rem;
    height: 11.2rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--card-color, $card-color);
    color: var(--primary-color, $primary-color);
    padding-left: 2.45rem;
    padding-right: 2.45rem;
    cursor: pointer;
    .lenderName {
      margin-left: 1.2rem;
      line-height: 1.8rem;
      color: var(--body-text-color, $body-text-color);
    }
    .lenderLogo {
      width: 4.8rem;
      min-width: 4.8rem;
      height: 4.8rem;
      position: relative;
    }
  }
  .diagonalArrow {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
  }
}
