//Specialized Onboarding Styles

.guardianInfoContainer {
  .guardianStart {
    padding-top: 5.6rem;
    @media ($bp-md) {
      padding-top: 0;
    }
    h1 {
      @media ($bp-md) {
        margin-bottom: 0;
      }
    }
  }
  .guardianInfoText {
    h5 {
      margin-bottom: 3.2rem;
    }
  }
  .topSpacer {
    padding-top: 1.6rem;
  }
  .homeowner {
    margin-bottom: 0.4rem;
  }
}
