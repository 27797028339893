.layout {
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: relative;
  .loading-bar {
    z-index: 100;
    position: absolute;
    background: var(--border-color, $border-color);
    height: 100%;
    width: 100%;
    transition: all 0.25s;
    .progress {
      background: var(--primary-color, $primary-color);
      height: 100%;
      transition: all 1s;
    }
  }
}

nav[aria-label="secondary"] {
  background-color: var(--secondary-color, $secondary-color);
}

main {
  margin: 0 auto;
  width: 100%;
  @media ($bp-lg) {
    &.less-width {
      width: calc(100% - 26rem);
    }
  }
}

section,
.section {
  width: 100%;
  height: 100%;
  background: var(--hairline-color, $hairline-color);
  color: var(--body-text-color, $body-text-color);
}

.container {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin: 0 auto;
  @media ($bp-md) {
    padding-right: 3.2rem;
    padding-left: 3.2rem;
  }
  padding-bottom: 3.2rem;
  &:first-child {
    padding-top: 3.2rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.rowToCol {
  @apply flex flex-col;
  // flex-wrap: wrap;
  width: 100%;
  :last-child {
    margin-bottom: 0;
  }
  @mixin desktopCol() {
    @apply flex-row;
    > * {
      margin-bottom: 0;
    }
    &.gap {
      width: calc(100% + 3.2rem);
      margin-right: -3.2rem;
      > * {
        margin-right: 3.2rem;
      }
    }
  }
  &.sm {
    @media ($bp-sm) {
      @include desktopCol();
    }
  }
  &.md {
    @media ($bp-md) {
      @include desktopCol();
    }
  }
  &.lg {
    @media ($bp-lg) {
      @include desktopCol();
    }
  }
  &.xl {
    @media ($bp-xl) {
      @include desktopCol();
    }
  }
}

.footer {
  position: relative;
  min-height: 6.8rem;
  background: var(--card-color, $card-color);
  color: var(--primary-color, $primary-color);
  .links {
    > * {
      margin: 1.2rem;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .logo {
    @apply rounded-full flex items-center justify-center;
    height: 3.8rem;
    width: 3.8rem;
    color: inherit;
    display: none;
  }
  @media (#{$bp-md}) {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    height: 9.6rem;
    margin-bottom: 0;
    .logo {
      display: block;
    }
  }
}

// This is a "mysterious" DIV that appears at the bottom of the page and occupies a lot of space
// Perhaps this comes from HubSpot
// We don't want to see it!
#hs-web-interactives-floating-container {
  display: none;
}