.searchContainer {
  flex: 1;
  min-height: 89rem;
  position: relative;
  background: var(--secondary-color, $secondary-color);
  .background {
    width: 100%;
    height: 100%;
    background: var(--secondary-color, $secondary-color);
    position: absolute;
    z-index: -1;
    @media ($bp-lg) {
      background: var(--tertiary-color, $tertiary-color);
    }
  }
  .searchBar {
    @media ($bp-md) {
      max-width: 133rem;
      min-width: 115rem;
    }
  }
  .emptyNest {
    font-size: 24px;
    position: absolute;
    line-height: normal;
    top: 6.5rem;
    @media ($bp-md) {
      position:relative;
      top: 0.5rem;
    }
    .chooseLabel {
      display: none;
      color: white;
      @media ($bp-md) {
        display: flex;
        position: absolute;
        top: 30rem;
        width: 80%;
        left: 5rem;
      }
      position: absolute;
      top: 26rem;
    }
  }
  .filter-container {
    @include darkShadow;
    max-width: 38rem;
    background: var(--card-color, $card-color);
    color: var(--body-text-color, $body-text-color);
    margin-bottom: 4.8rem;
    border-radius: 0.8rem;
    transform: translateY(6rem);
    z-index: 75;
    @media ($bp-lg) {
      max-width: 64rem;
      width:70%;
      display: none;
    }
  }
  .sort-container {
    @include darkShadow;
    max-width: 32.8rem;
    width:100%;
    background: var(--card-color, $card-color);
    color: var(--body-text-color, $body-text-color);
    margin-bottom: 4.8rem;
    border-radius: 0.8rem;
    transform: translateY(6rem);
    @media ($bp-lg) {
      width: 25.0rem;
      transform: translateY(6.8rem);
    }
  }
  .sort-options {
    padding: 1.2rem 0 1.2rem 0;
    border-radius: 0.8rem;
    border: 0.1rem solid var(--border-color, $border-color);
    width: 100%;
    .checkmark {
      color: var(--primary-color, $primary-color);
      width: 4.6rem;
      height: 4.6rem;
      min-width: 4.6rem;
      padding: 1.1rem;
    }
    .sort-option {
      cursor: pointer;
      height: 3.8rem;
      :nth-child(2) {
        width: 100%;
        padding: 1.2rem 0;
      }
    }
  }
  .topSearchBox {
    position: absolute;
    left: 2rem;
    top: 12rem;
    @media ($bp-md) {
      padding-left: 1.6rem;
      padding-top:.3rem;
      left: 0rem;
      position: static;
    }
  }
  .mapBar {
    @media ($bp-md) {
      width:35%;
      position: relative;
      left: 0rem;
      margin-left: 14.5rem;
    }
  }
  .addSchools {
    @media ($bp-md) {
      width:50%;
    }
    .bttnContainer {
      @media ($bp-md) {
        width: 50%;
        position: absolute;
        right: 2rem;
      }
    }
  }
  .results-sort {
    padding: 2.4rem 0;
    color: var(--secondary-color-invert, $secondary-color-invert);
    max-width: 92.4rem;
    width:30%;
    z-index: 10;
    @media ($bp-lg) {
      padding: 1.6rem 0;
      width:50%;
    }
    small {
      color: var(--label-text-color, $label-text-color);
    }
  }
  .mapContainer {
    min-height: 21.8rem;
    height: 21.8rem;
    margin-top: 2.4rem;
    @media ($bp-md) {
      min-height: 43.2rem;
      height: 43.2rem;
      margin-right: 4.8rem;
      width:100%;
      position: relative;
      left: 0rem;
    }
    .map {
      height: 100%;
      width: 100%;
      border-radius: 0.8rem;
      background: var(--border-color, $border-color);
      overflow: hidden;
      position: relative;
    }
  }
  .mapSearchContainer {
    z-index: 10;
  }
  .or {
    @media ($bp-md) {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 2rem;
      color: $cap-blue;
      width: 100%;
      text-align: center;
    }
  }
  .description {
    margin-bottom: 1.3rem;
    @media ($bp-md) {
      font-size: 1.3rem;
      font-weight: bold;
      line-height: 2.4rem;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
  .advancedChoice {
    background: var(--background-color, #f6f6f6);
    border-radius: 0.6rem;
    border: 1px solid;
    min-height: 5rem;
    padding-top: 5px;
  }
  .leftSearchBox {
    display: none;
    @media ($bp-md) {
      min-width: 21rem;;
      width: 20%;
      display: flex;
      flex-direction: column;
    }
  }
  .schoolList {
    padding-left: 1.6rem;
    padding-bottom: 1.6rem;
    padding-right: 1.6rem;
    margin-top: 0rem;
    margin-left: 1.5rem;
    background-color:rgb(231, 226, 226);
    border-radius: 1rem;
    z-index: 15;
    width: 92%;
    position: fixed;
    top:20rem;
    @media ($bp-md) {
      border-radius: 0.5rem;
      padding-top: 1rem;
      padding-right: 1.4rem;
      margin-top: -18.4rem;
      margin-left: 0rem;
      position: relative;
      top: 18.7rem;
      width: 94%;
      left: 2rem;
      border-radius: 8px;
    }
    .results {
      background-color:rgb(231, 226, 226);
      color: rgb(67, 66, 66);
      padding: 1rem 0rem 0rem 1rem;
    }
    .curatedGallery {
      // max-width: 92.4rem;
      .cardContainer {
        margin-bottom: 1.2rem;
      }
      > .cardContainer:last-child {
        margin-bottom: 0;
      }
    }
  }
  .curatedGallery {
    // max-width: 92.4rem;
    .cardContainer {
      margin-bottom: 1.2rem;
    }
    > .cardContainer:last-child {
      margin-bottom: 0;
    }
  }
  .CAPSearchBtnContainer {
    display: none;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 4.8rem;
    top:10rem;
    position: absolute;
    width: 100%;
    .spacer {
      height: 2.4rem;
    }
    button {
      width: 84%;
      max-width: 32.8rem;
      height: 5.6rem;
    }
  }
}

.school-search {
  @include lightShadow;
  margin-right: .5rem;
  margin-left: .5rem;
  position: relative;
  border: 0.1rem solid var(--border-color, $border-color);
  border-radius: 0.8rem;
  min-height: 5.4rem;
  max-height: 30.8rem;
  max-width: 60.8rem;
  background: var(--input-color, $input-color);
  color: var(--input-text-color, $input-text-color);
  overflow: hidden;
  z-index: 50;
  @media ($bp-md) {
    margin-right: 0;
    max-width: 100%;
    left: 0rem;
  }
  .search-bar {
    height: 5.4rem;
    display: flex;
    flex-flow: row nowrap;
    &.searching {
      border-bottom: 0.1rem solid var(--border-color, $border-color);
      margin-bottom: -0.1rem;
    }
    .school-search-input {
      width: 100%;
      height: 5.4rem;
      position: relative;
      .search-icon {
        width: 1.8rem;
        color: var(--primary-color, $primary-color);
      }
      .results-number {
        display: none;
        color: var(--progress-color, $progress-color);
        pointer-events: none;
        position: absolute;
        right: 0;
        user-select: none;
        white-space: nowrap;
        span {
          margin-bottom: 0;
        }
      }
      input {
        border: none;
        background: transparent;
        height: 100%;
        text-indent: 1.6rem;
        box-shadow: none;
        width: 16.8rem;
      }
    }
    .cancel-btn {
      display: none;
      color: var(--primary-color, $primary-color);
      padding-right: 2.4rem;
      padding-left: 2.4rem;
      @include bodySmall;
    }
    &.focused {
      .search-icon {
        display: none;
      }
      input {
        width: 100%;
        text-indent: 2.4rem;
      }
      .results-number {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
      }
      .cancel-btn {
        display: block;
        height: 100%;
      }
    }
  }
  .search-list {
    overflow-y: scroll;
    max-height: 0;
    transition: max-height 0.35s ease;
    .search-item {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      cursor: pointer;
      span {
        margin-bottom: 0.4rem;
      }
    }
    > :first-child {
      padding-top: 2.4rem;
    }
    > :last-child {
      margin-bottom: 0;
      padding-bottom: 2.4rem;
    }
    &.expanded {
      max-height: 24.4rem;
    }
  }
}

.onboarding-school-search {
  margin: 0;
  border-radius: 0.4rem;
  min-height: unset;
  .search-bar {
    height: 4.8rem;
    .school-search-input {
      height: 4.8rem;
      input {
        width: 100%;
        text-indent: 1.2rem;
      }
      &:focus-within {
        input {
          text-indent: 1.2rem;
        }
      }
    }
  }
  .search-list {
    .search-item {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
    > :first-child {
      padding-top: 1.2rem;
    }
    > :last-child {
      padding-bottom: 1.2rem;
    }
  }
}
