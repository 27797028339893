.lockupContainer {
  @include upShadow;
  bottom: 0;
  padding: 3.0rem, 2.4rem, 2.4rem, 2.4rem;
  background: var(--card-color, $card-color);
  .createAccountBtn {
    height: 4.8rem;
    width: 32.7rem;
  }
  .help-btn {
    height: 4rem;
    width: 4rem;
    // font-family: "AvenirNextMedium";
    span {
      padding-top: 0.2rem;
    }
  }
  .nextBtn {
    height: 4.8rem;
    width: 15.9rem;
  }
  .skip {
    width: 4rem;
    height: 3.2rem;
  }
}
