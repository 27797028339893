.card {
  @include lightShadow;
  background: var(--card-color, $card-color);
  border: 0.1rem solid var(--border-color, $border-color);
  border-radius: 0.8rem;
  padding: 2.4rem;
  .topHeading {
    position: relative;
    .schoolLogo {
      min-width: 5.6rem;
      width: 5.6rem;
      height: 5.6rem;
    }
  }
  .emptyCard {
    background: var(--background-color, $background-color);
    width: 100%;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @media ($bp-md) {
      padding-top: 5.2rem;
      padding-bottom: 5.2rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    h4 {
      @media ($bp-md) {
        margin-bottom: 0;
      }
    }
    .description {
      width: 75%;
      margin-bottom: 2.4rem;
      @media ($bp-md) {
        @include bodyBig;
        width: 100%;
        margin-bottom: 0;
        margin-top: 1.6rem;
      }
    }
    button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
      @media ($bp-md) {
        @include h4;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }
    &.offers {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      @media ($bp-md) {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
      }
    }
    .comingSoonText {
      color: var(--primary-color, $primary-color);
    }
  }
  &.typeEmpty {
    padding: 0.8rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-bottom: 4.4rem;
    @media ($bp-md) {
      margin-left: 4.8rem;
      margin-right: 4.8rem;
    }
  }
  &.typeDark {
    background: var(--secondary-color, $secondary-color);
    .emptyCard {
      background: transparent;
      color: var(--secondary-color-invert, $secondary-color-invert);
      .icon {
        width: 3.4rem;
        min-width: 3.4rem;
        height: 3.4rem;
        margin-bottom: 1.6rem;
        color: var(--primary-color, $primary-color);
      }
      .header {
        margin-bottom: 0.8rem;
        color: var(--highlight-text-color, $highlight-text-color);
        @media ($bp-md) {
          margin-bottom: 0.8rem;
        }
      }
      .description {
        margin-bottom: 3.2rem;
        @media ($bp-md) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      button {
        &.highlight {
          &:hover {
            color: var(--tertiary-color, $tertiary-color);
            background: var(--secondary-color-invert, $secondary-color-invert);
            border-radius: 0.8rem;
          }
        }
      }
    }
    .notice {
      .icon {
        width: 3.4rem;
        min-width: 3.4rem;
        height: 3.4rem;
        color: $cap-dark-orange;
      }
    }
    .rocket {
      .icon {
        width: 4.8rem;
        min-width: 4.8rem;
        height: 4.8rem;
        color: var(--upgrade-button-color, $upgrade-button-color);
      }
    }
    .close-card {
      width: 3.2rem;
      padding: 0.5rem;
      position: absolute;
      top: -1.6rem;
      right: -1.6rem;
      background-color: var(--background-color, $background-color);
      // border: 0.1rem solid;
      &:hover {
        @include lightShadow;
        background: var(--button-highlight-color, $button-highlight-color);
        color: var(--button-highlight-text-color, $button-highlight-text-color);
        border: 0.1rem solid;
        //   var(--button-highlight-color, $button-highlight-color);
      }
      &.spin-shrink {
        @include lightShadow;
        background: var(--button-highlight-color, $button-highlight-color);
        color: var(--button-highlight-text-color, $button-highlight-text-color);
        border: 0.1rem solid;
        transition: all 0.35s ease;
        width: 0;
        padding: 0;
        top: 0;
        right: 0;
        transform: rotate(90deg);
      }
    }
  }
  &.typeTall {
    height: 100%;
    .schoolLogo {
      margin-bottom: 1.2rem;
    }
    .quote {
      margin-bottom: 2.4rem;
      small {
        margin-bottom: 0.4rem;
      }
      .need-more-info {
        position: absolute;
        right: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;

        button.alert {
          color: red;
          border-color: red;
          border-width: 2px;
        }
        button.alert:hover {
          background-color: red;
        }

      }
    }
    .remove {
      position: absolute;
      color: var(--primary-color, $primary-color);
      right: 0;
      bottom: 0;
      width: 4rem;
      height: 4rem;
      padding: 1.2rem;
      margin: 1.2rem;
    }
  }
  &.typeShort {
    //TODO: refactor with school_card.scss
    @media ($bp-md) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }
    .basicSchoolInfo {
      position: relative;
      .rank {
        font-family: "AvenirNextDemiBold";
        @media ($bp-md) {
          font-family: "AvenirNextMedium";
        }
      }
      .topHeading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        @media ($bp-md) {
          margin-bottom: 0;
        }
        .pinSticker {
          margin-right: 2.4rem;
        }
      }
      .forbesRanking {
        @media ($bp-md) {
          margin-bottom: 0;
        }
      }
      .quote {
        margin-bottom: 0;
        @media ($bp-md) {
          width: 50%;
        }
        .stickerPrice {
          @media ($bp-md) {
            padding-left: 2.5rem;
          }
        }
        .netCost {
          @media ($bp-md) {
            padding-left: 0.3rem;
          }
        }
      }
      .checkbox {
        position: absolute;
        right: 0;
      }
    }
    .advancedUpperInfo {
      height: 12.6rem;
      z-index: 10; //allow clickable spacing around dropdown button
      color: rgb(65, 64, 64);
      @media ($bp-md) {
        height: 4.8rem;
      }
      .topHeading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.6rem;
        @media ($bp-md) {
          margin-bottom: 0;
        }
        .schoolLogo {
          min-width: 4.8rem;
          width: 4.8rem;
          height: 4.8rem;
        }
      }
      .affordability {
        margin-bottom: 2.4rem;
        @media ($bp-md) {
          margin-bottom: 0;
          width: 30%;
        }
      }
      small {
        margin-bottom: 0;
      }
      .quote {
        @media ($bp-md) {
          width: 30%;
        }
        > * {
          line-height: 1.8rem;
        }
      }
    }
    .collapsible {
      .title {
        margin-bottom: 1.2rem;
      }
      .requirements {
        margin-bottom: 2.4rem;
        .scores {
          > * {
            width: 5.6rem;
            margin-bottom: 0.4rem;
          }
          :last-child {
            margin-right: 2.4rem;
          }
        }
      }
      .description {
        white-space: pre-line;
        div {
          margin-bottom: 1rem;
        }
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
