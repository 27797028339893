.entryPage {
  position: relative;
  .entryPortal {
    background: var(--secondary-color, $secondary-color);
    padding: 2.4rem;
    min-width: max(23%, 31rem);
    .desktop-logo {
      height: 4.8rem;
      width: 100%;
      cursor: default;
    }
    .entryFontBig {
      font-size: 3.6rem; //Bigger than h1
      line-height: 4rem;
      letter-spacing: 0.8rem;
      font-family: "AvenirNextBold";
      color: var(--primary-color, $primary-color);
      margin-right: -1rem; //counter act letter spaing for right edge
      margin-bottom: 0.4rem;
    }
    .entryFontMedium {
      @include h3;
      letter-spacing: 0.8rem;
      color: var(--secondary-color-invert, $secondary-color-invert);
      margin-right: -1rem; //counter act letter spaing for right edge
    }
    .divider {
      background: var(--primary-color, $primary-color);
      width: 13rem;
      height: 0.3rem;
    }
    ol {
      @include h4;
      letter-spacing: 0.3rem;
      color: var(--secondary-color-invert, $secondary-color-invert);
      list-style-position: inside;
      li {
        white-space: nowrap;
        margin-left: 0;
      }
    }
    .startBtn {
      width: 100%;
      max-width: 29.2rem;
      height: 5.6rem;
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
      button {
        &:hover {
          //custom hover style for landing page
          background: var(--secondary-color-invert, $secondary-color-invert);
          color: var(--secondary-color, $secondary-color);
        }
      }
    }
    span {
      color: var(--quaternary-color, $quaternary-color);
    }
    .loginLink {
      font-family: "AvenirNextBold";
      padding: 1.6rem;
      @media (#{$bp-md}) {
        padding: 2.4rem;
      }
    }
  }
  .entryImage {
    position: relative;
    max-width: 128rem;
    margin: auto;
    .title {
      @include h1;
      letter-spacing: 0.8rem;
      color: $cap-navy;
      padding: 0 4rem;
    }
    .imageFile {
      max-width: 108rem;
      max-height: 80%;
      img {
        padding: 0 2.4rem !important;
        object-position: 50% 25%;
      }
    }
  }
}

.welcomeContainer {
  position: relative;
  width: 100%;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  @media ($bp-md) {
    position: initial;
    padding-top: 3.2rem;
  }
  form {
    max-width: 49.8rem;
    margin: auto;
  }
  .bgLogo {
    position: absolute;
    color: var(--primary-color, $primary-color);
    z-index: -1;
    width: 90%;
    max-width: 60rem;
    align-self: center;
    bottom: 0;
    opacity: 0.15;
    transform: translateX(-35vw);
    @media ($bp-md) {
      left: 0;
      transform: none;
    }
  }
  .welcomeText {
    @media (#{$bp-md}) {
      margin-top: 3.2rem;
    }
    .myCap {
      width: 20rem;
      margin-top: 3.2rem;
      margin-bottom: 4.8rem;
      @media (#{$bp-md}) {
        width: 25.4rem;
        margin-top: 6.4rem;
      }
    }
    .entryText {
      max-width: 80%;
    }
  }
  .loginText {
    margin-top: 4.8rem;
  }
  .resetPasswordText {
    margin-top: 6.4rem;
    margin-bottom: 3.2rem;
  }
  .checkEmailText {
    margin-top: 14rem;
    margin-bottom: 3.2rem;
  }
  .checkEmailBtn {
    width: 100%;
    max-width: 29.2rem;
    height: 5.6rem;
    margin-bottom: 3.2rem;
  }
  .tryAgain {
    margin-top: 11.2rem;
  }
  .entry {
    padding-top: 4.8rem;
    padding-bottom: 3.2rem;
  }
  .welcome {
    padding-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .startBtn {
    width: 100%;
    max-width: 29.2rem;
    height: 5.6rem;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .loginBtn {
    margin-top: 1.6rem;
  }
  .loginLink {
    padding: 1.6rem;
    @media (#{$bp-md}) {
      padding: 2.4rem;
    }
  }
  .loginError {
    margin-bottom: 1.6rem;
  }
  .situationOption {
    @apply flex items-center justify-start;
    height: 8.8rem;
  }
}
