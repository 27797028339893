// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Skeleton
// are based on 10px sizing. So basically 1.5rem = 15px :)
html {
  font-size: 62.5%;
  @media (min-width: 2240px) {
    font-size: 72.9166666667%;
  }
  @media (min-width: 2560px) {
    font-size: 83.3333333333%;
  }
  @media (min-width: 2880px) {
    font-size: 93.75%;
  }
  @media (min-width: 3200px) {
    font-size: 104.166666667%;
  }
  @media (min-width: 3520px) {
    font-size: 114.583333333%;
  }
  @media (min-width: 3840px) {
    font-size: 125%;
  }
}

html,
body {
  min-height: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  color: var(--body-text-color, $body-text-color);
  background: var(--background-color, $background-color);
  > * {
    height: 100%;
    width: 100%;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

ul {
  text-align: left;
  li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1.7rem;
    span {
      position: relative;
      left: -0.2rem;
    }
  }
  &.checklist {
    li {
      // list-style-image: url("/images/check-mark-transparent.png"); //TODO: needs to be able to change color
      margin-bottom: 1.2rem;
      margin-left: -1rem;
      list-style: none;
      display: flex;
      align-items: center;
      &:before {
        align-self: flex-start;
        color: transparent;
        font-size: 1px;
        content: " ";
        margin-left: 1rem;
        margin-right: 0.5rem;
        margin-top: 0.2rem;
        padding: 0.5rem;
        background-color: var(--upgrade-button-color-color, $upgrade-button-color);
        -webkit-mask-image: url("/images/check-mark-transparent.png");
        -webkit-mask-size: cover;
      }
      &.not {
        &:before {
          background-color: var(--error-color, $error-color);
          -webkit-mask-image: url("/images/x-mark-transparent.png");
          -webkit-mask-size: cover;
        }
      }
    }
  }
}

ol {
  text-align: left;
  li {
    list-style-type: decimal;
    display: list-item;
    margin-left: 1.7rem;
    span {
      position: relative;
      left: -0.2rem;
    }
  }
}

button {
  border-radius: 0.8rem;
  &.primary {
    @include darkShadow;
    background: linear-gradient(
      0deg,
      var(--primary-color-highlight, $primary-color-highlight) 0%,
      var(--primary-color, $primary-color) 100%
    );
    color: var(--button-text-color, $button-text-color);
    &:hover {
      background: var(--button-highlight-color, $button-highlight-color);
      color: var(--button-highlight-text-color, $button-highlight-text-color);
    }
    &:disabled {
      background: var(--contrast-color, $contrast-color);
      color: var(--disabled-color, $disabled-color);
      border: 0.1rem solid var(--disabled-color, $disabled-color);
    }
  }
  &.secondary {
    @include lightShadow;
    background: var(--primary-color, $primary-color);
    color: var(--button-text-color, $button-text-color);
    font-size: 1.6rem;
    font-weight: bold;
    width: 100%;
    @media ($bp-md) {
      width: 75%;
    }

    &:hover {
      background: var(--button-highlight-color, $button-highlight-color);
      color: var(--button-highlight-text-color, $button-highlight-text-color);
    }
    &:disabled {
      background: var(--contrast-color, $contrast-color);
      color: var(--disabled-color, $disabled-color);
      border: 0.1rem solid var(--disabled-color, $disabled-color);
    }
  }
  &.tertiary {
    color: var(--primary-color, $primary-color);
    border: 0.1rem solid var(--primary-color, $primary-color);
    &:hover {
      @include lightShadow;
      background: var(--button-highlight-color, $button-highlight-color);
      color: var(--button-highlight-text-color, $button-highlight-text-color);
      border: 0.1rem solid
        var(--button-highlight-color, $button-highlight-color);
    }
    &:disabled {
      // background: transparent;
      background: var(--contrast-color, $contrast-color);
      color: var(--disabled-color, $disabled-color);
      border: 0.1rem solid var(--disabled-color, $disabled-color);
    }
  }
  &.tertiary-dark {
    color: var(--secondary-color-invert, $secondary-color-invert);
    // border: 0.1rem solid var(--secondary-color-invert, $secondary-color-invert); //use distinct variable for color to allow no changes to whitelabel
    &:hover {
      color: var(--tertiary-color, $tertiary-color);
      background: var(--secondary-color-invert, $secondary-color-invert);
      border-radius: 0.8rem;
    }
    &:disabled {
      // background: transparent;
      background: var(--contrast-color, $contrast-color);
      color: var(--disabled-color, $disabled-color);
      border: 0.1rem solid var(--disabled-color, $disabled-color);
    }
  }
  &.highlight {
    @include lightShadow;
    background: var(--upgrade-button-color, $upgrade-button-color);
    color: var(--button-text-color, $button-text-color);
    &:hover {
      background: var(--button-highlight-color, $button-highlight-color);
      color: var(--button-highlight-text-color, $button-highlight-text-color);
    }
    &:disabled {
      background: transparent;
      color: var(--disabled-color, $disabled-color);
      border: 0.1rem solid var(--disabled-color, $disabled-color);
    }
  }
  &.inherit {
    //inherit styles
    color: inherit;
    border: 0.1rem solid;
  }
  &.simple {
    color: var(--primary-color, $primary-color);
    &:hover {
      color: var(--button-highlight-color, $button-highlight-color);
    }
  }
  &.simpleDark {
    color: var(--primary-color, $primary-color);
    &:hover {
      color: var(--highlight-text-color, $highlight-text-color);
    }
  }
  &.imitateLink {
    color: var(--primary-color, $primary-color);
  }
  &.help-btn {
    border-color: var(--primary-color, $primary-color);
    color: var(--primary-color, $primary-color);
    right: 0;
    height: 2.8rem;
    width: 2.8rem;
    font-family: "AvenirNextMedium";
    @media ($bp-lg) {
      border-color: var(--primary-color, $primary-color);
      color: var(--primary-color, $primary-color);
    }
    div {
      color: inherit;
      padding-top: 0.2rem;
    }
    &:hover {
      div {
        color: var(--button-highlight-text-color, $button-highlight-text-color);
      }
    }

    // For help bubbles in advanced search
    &.small {
      height: 2rem;
      width: 2rem;

      div {
        padding-top: 0;
      }
    }
    &.right {
      float: right;
    }

    &.efcHelp {
      &:hover {
        border-color: var(--secondary-color-invert, $secondary-color-invert);
        background: var(--secondary-color-invert, $secondary-color-invert);
        div {
          color: var(--button-highlight-color, $button-highlight-color);
        }
        @media ($bp-lg) {
          border-color: var(--button-highlight-color, $button-highlight-color);
          background: var(--button-highlight-color, $button-highlight-color);
          div {
            color: var(
              --button-highlight-text-color,
              $button-highlight-text-color
            );
          }
        }
      }
    }
  }
  &:disabled {
    box-shadow: none;
    color: var(--disabled-color, $disabled-color);
    cursor: not-allowed;
    &:hover {
      div {
        color: var(--disabled-color, $disabled-color);
      }
    }
  }
}

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 0.1rem solid var(--border-color, $border-color);
}

//***** Divider *****//
.divider {
  width: 100%;
  height: 0.1rem;
  background: var(--border-color, $border-color);
}

.vertical-divider {
  width: 0.1rem;
  background: var(--border-color, $border-color);
}

//Inputs
//––––––––––––––––––––––––––––––––––––––––––––––––––

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    margin: 0;
  }
}

//Default styles for text, password and number inputs
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  @include lightShadow;
  height: 4.8rem;
  border: 0.1rem solid var(--border-color, $border-color);
  border-radius: 0.25rem;
  text-indent: 1rem;
  &:focus {
    outline: none;
  }
  &.inputErrorState {
    border: 0.2rem solid var(--error-color, $error-color);
  }
  &:disabled {
    color: var(--disabled-text-color, $disabled-text-color);
  }
}

//Default styles for number inputs
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

//Default styles for radio inputs
input[type="radio"] {
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
  margin: 0;
}

//Default styles for select inputs
select {
  @include lightShadow;
  height: 4.8rem;
  border: 0.1rem solid var(--border-color, $border-color);
  border-radius: 0.25rem;
  text-indent: 1rem;
  font-size: 1.6rem; //Prevents auto zoom on iOS, 16px is minimum
  -webkit-appearance: none;
  background: var(--quinary-color, $quinary-color);
  &:focus {
    outline: none;
  }
}

.select-icon {
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  color: var(--primary-color, $primary-color);
  right: 1.1rem;
  bottom: 1.6rem;
  user-select: none;
  pointer-events: none;
}

//Default styles for range inputs
input[type="range"] {
  -webkit-appearance: none; // Hides the slider so that custom slider can be made
  width: 100%; // Specific width is required for Firefox
  background: transparent; // Otherwise white in Chrome
  position: relative; //Ensures the layering will not interfere with under/overlapping content
  height: 2.4rem;
  &::-webkit-slider-thumb {
    @include thumb;
    @include darkShadow;
    -webkit-appearance: none;
  }
  &::-moz-range-thumb {
    @include thumb;
    @include darkShadow;
  }
  &:focus {
    outline: none;
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;

    // Hides the slider so custom styles can be added
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

//Checkboxes
//Standard
.checkbox {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  min-width: 2.4rem;
  user-select: none; //stop custom checkmark from being selected
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    &:checked ~ .checkmark {
      background: var(--primary-color, $primary-color);
      border: 0.1rem solid var(--primary-color, $primary-color);
      svg {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.1rem solid var(--border-color, $border-color);
    border-radius: 0.4rem;
    pointer-events: none;
    color: var(--highlight-text-color, $highlight-text-color);
    svg {
      display: none;
    }
  }
}

//Toggle Switch
.toggle {
  position: relative;
  display: inline-block;
  width: 7.2rem;
  height: 3.2rem;
  user-select: none; //stop custom checkmark from being selected
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: var(--primary-color, $primary-color);
      &:before {
        transform: translateX(4rem);
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tertiary-color, $tertiary-color);
    border-radius: 3.2rem;
    color: var(--secondary-color-invert, $secondary-color-invert);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 2.4rem;
      width: 2.4rem;
      left: 0.4rem;
      bottom: 0.4rem;
      background-color: var(--highlight-text-color, $highlight-text-color);
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a,
.link {
  color: var(--primary-color, $primary-color);
  cursor: pointer;
  &.inherit {
    color: inherit;
  }
}

// Desktop Logo

.desktop-logo {
  position: relative;
  height: 4.1rem;
  min-width: 17.8rem;
  min-width: auto;
  max-width: 17.8rem;
  cursor: pointer;
}

.whitelabel-logo {
  margin-top: -0.4rem;
  position: absolute;
  height: 4.9rem;
  width: 100%;
}

.whitelabel-mycap {
  width: 15rem;
  height: 4.8rem;
}

.powered-by-cap {
  width: 15rem;
  height: 1.5rem;
  right: -15rem;
  // bottom: -1.9rem;
  position: absolute;
}

//Loading

.page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border-color: var(--primary-color, $primary-color);
}
