//Universal onboarding styles

.onboardingContainer,
.create-account-form {
  // position: absolute;
  .onboardingUpgradePlan {
    min-height: calc(100% - 16.8rem);
  }
  .onboardingScreen {
    min-height: calc(100% - 16.8rem);
    width: 100%;
    max-width: 49.8rem;
    margin: auto;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  .notForm {
    min-height: 100%;
  }

  .spinnerContainer {
    margin-top: 4rem;
    margin-bottom: 3.2rem;
  }

  //***** Progress Bar *****//
  .progressInfo {
    padding-top: 1.4rem;
    padding-bottom: 4.8rem;
    @media ($bp-md) {
      padding-top: 5.6rem;
      padding-bottom: 7.2rem;
    }
    .infoStep {
      color: var(--progress-color, $progress-color);
    }
    .progress-bar {
      height: 1.6rem;
      background: var(--border-color, $border-color);
      .progress {
        height: 100%;
        background: var(--primary-color, $primary-color);
      }
    }
  }
  //***** Divider *****//
  .divider {
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
    @media ($bp-md) {
      margin-top: 3.2rem;
      margin-bottom: 4.8rem;
    }
    &.removeDividerBottom {
      margin-bottom: 0;
    }
  }
  //***** Inputs *****//
  .inputs {
    width: 100%;
  }

  .loginError {
    color: var(--error-color, $error-color);
    margin-bottom: 2.2rem;
    margin-top: -1.6rem;
  }
  .selectLabel {
    @apply flex flex-col;
    margin-bottom: 1.6rem;
    span {
      margin-bottom: 0.5em;
    }
  }
  .textLabel {
    @apply flex flex-col;
    margin-bottom: 1.6rem;
    span {
      margin-bottom: 0.5em;
    }
  }
  .currencyLabel {
    position: relative;
    input {
      text-indent: 2.2rem; //override default style
    }
    .currency {
      color: var(--border-color, $border-color);
      position: absolute;
      bottom: 1.4rem;
      left: 1.2rem;
    }
  }
  .numberLabel {
    @apply flex flex-row items-center justify-center;
    margin-bottom: 1.6rem;
    .numDisplay {
      width: 6.4rem;
      margin-bottom: 0;
    }
    .incButton {
      @apply flex items-center justify-center;
      width: 4.8rem;
      height: 4.8rem;
      border: 0.1rem solid var(--border-color, $border-color);
      border-radius: 0.5rem;
      background: var(--quinary-color, $quinary-color);
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.4rem;
        height: 1.4rem;
        color: var(--body-text-color, $body-text-color);
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .sliderLabel {
    @apply flex flex-col;
    margin-bottom: 2.4rem;
    .sliderText {
      @apply flex justify-between;
      margin-bottom: 1.4rem;
      * {
        margin-bottom: 0;
      }
    }
    .sliderProgressContainer {
      @apply flex items-center;
      height: 2.4rem; //height of thumb
      margin-top: -2.4rem;
      transform: translateY(2.4rem);
      width: 100%;
      .sliderProgressBar {
        @include track;
        background: var(--border-color, $border-color);
        border-radius: 0.5rem;
        overflow: hidden;
        .sliderProgress {
          height: 100%;
          background: var(--primary-color, $primary-color);
        }
      }
    }
    .minMax {
      margin-top: 0.6rem;
    }
  }
  .radioOption {
    width: 100%;
    height: 3.2rem;
    margin-bottom: 1.2rem;
  }
}
