input[type="radio"] {
  &:checked + .radioOption {
    background: var(--hairline-color, $hairline-color);
    border: 0.1rem solid var(--primary-color, $primary-color);
    .radioControl {
      background: radial-gradient(
        var(--primary-color, $primary-color) 58%,
        var(--quinary-color, $quinary-color) 59%
      );
    }
  }
}
.radioOption {
  @include lightShadow;
  @apply flex items-center justify-center;
  cursor: pointer;
  height: 3.2rem;
  border: 0.1rem solid var(--border-color, $border-color);
  border-radius: 0.8rem;
  padding: 0.8rem;
  font-family: "AvenirNextDemiBold";
  background: var(--card-color, $card-color);
  color: var(--label-text-color, $label-text-color);
  .radioControl {
    @apply rounded-full block;
    width: 2.4rem;
    height: 2.4rem;
    margin: 2.4rem;
    border: 0.1rem solid var(--border-color, $border-color);
  }
  .radioLabel {
    line-height: 1;
  }
}

.currencyLabel {
  position: relative;
  input {
    text-indent: 2.2rem; //override default style
  }
  .currency {
    color: var(--border-color, $border-color);
    position: absolute;
    bottom: 1.4rem;
    left: 1.2rem;
  }
}
