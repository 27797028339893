//Fonts
@mixin h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  font-family: "AvenirNextBold";
}

@mixin h3 {
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: "AvenirNextBold";
}

@mixin h4 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: "AvenirNextDemiBold";
}

@mixin h6 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "AvenirNextDemiBold";
}

@mixin bodySmall {
  font-size: 1.4rem;
  line-height: 2rem;
}

@mixin bodyRegular {
  font-size: 1.6rem;
  line-height: 2rem;
}

@mixin bodyBig {
  //Standard body text but bigger
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: "AvenirNextRegular";
}

//Colors
@mixin colorRed {
  color: var(--error-color, $error-color);
}

//Inputs
//Range Slider
@mixin thumb {
  box-sizing: content-box;
  border: 0.2rem solid var(--quinary-color, $quinary-color);
  height: 2.4rem;
  width: 4.8rem;
  border-radius: 1rem;
  background: var(--primary-color, $primary-color);
  cursor: pointer;
}

@mixin track {
  cursor: pointer;
  height: 0.3rem;
  transition: all 0.2s ease;
  width: 100%;
}

//Shadows
@mixin darkShadow {
  box-shadow: 0rem 1.6rem 2.4rem -0.4rem $shadow1;
}

@mixin lightShadow {
  box-shadow: 0rem 1.6rem 2.4rem -0.4rem $shadow2;
}

@mixin upShadow {
  box-shadow: 0rem -1.6rem 2.4rem -0.4rem $shadow2;
}

@mixin fullShadow {
  box-shadow: 0rem 1.6rem 2.4rem -0.4rem $shadow2,
    0rem -1.6rem 2.4rem -0.4rem $shadow2;
}

//Animations
@mixin shake {
  animation: shake 0.5s linear both;
  transform: translate3d(0, 0, 0);
}
