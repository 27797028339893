.form-loading {
  > * {
    pointer-events: none;
  }
}

.translation-loading {
  background: rgba(var(--tertiary-color-rgb, $tertiary-color-rgb), 0.8);
  margin-top: 5.2rem;
  padding-bottom: 7.2rem;
  padding-top: 2.4rem;
  @media ($bp-md) {
    margin-top: 7.2rem;
    padding-top: 7.2rem;
  }
}

.card-container {
  max-width: 60.8rem;
  .card {
    padding: 2.4rem;
    @media ($bp-md) {
      padding: 4rem;
    }
    .h1 {
      margin-top: 1.6rem;
      @media ($bp-md) {
        margin-top: 0;
      }
    }
    .hideInfoBtn {
      min-height: 4.8rem;
      width: 15.9rem;
      margin-top: 4rem;
    }
    .close-popup {
      position: absolute;
      color: var(--primary-color, $primary-color);
      right: 0;
      top: 0;
      width: 4.8rem;
      height: 4.8rem;
      padding: 1.2rem;
      margin: 1.2rem;
    }
  }
  &.translation-success {
    max-width: 40rem;
    .h1 {
      color: var(--upgrade-button-color, $upgrade-button-color);
    }
  }
  &.translation-failure {
    .card {
      .notice {
        .icon {
          width: 5.6rem;
          min-width: 5.6rem;
          height: 5.6rem;
          color: $cap-dark-orange;
        }
      }
      .checklist {
        margin-bottom: 1.2rem;
        li {
          margin-bottom: 0.8rem;
          &:before {
            margin-top: 0.5rem;
          }
        }
      }
      .details {
        background: var(--contrast-color2, $contrast-color2);
        border-radius: 0.8rem;
      }
    }
  }
}

.offers-container {
  .checkmark {
    color: var(--primary-color, $primary-color);
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
  }
}

.add-offer-card {
  min-height: 30rem;
}

.offer-info-edit-block {
  width: 100%;
  height: 100%;
  max-width: 60.8rem;
  // margin-bottom: 8rem;
  &.paddingBottom {
    padding-bottom: 3.2rem;
  }
  &.borderBottom,
  .borderBottom {
    border-bottom: 0.1rem solid var(--border-color, $border-color);
  }
  .introText {
    max-width: 41rem;
    margin: auto;
  }
  h2 {
    margin-bottom: 0;
  }
  h3 {
    padding-bottom: 1.6rem;
    margin-bottom: 3.2rem;
    border-bottom: 0.1rem solid var(--border-color, $border-color);
  }
  .contentItem {
    border-bottom: 0.1rem solid var(--hairline-color, $hairline-color);
  }
  .sum-list-item {
    color: var(--progress-color, $progress-color);
  }
  .upload-offer-btn {
    width: min-content;
    white-space: nowrap;
    padding: 1.2rem 2.4rem;
  }
  .inputItem {
    .help-btn {
      height: 2.4rem;
      width: 2.4rem;
    }
    .labelWrapper {
      margin-bottom: 0.8rem;
    }
    .value-type {
      color: var(--primary-color, $primary-color);
      position: absolute;
      // bottom: 1.4rem;
      bottom: 0;
      // right: 1.2rem;
      right: 0;
      padding: 0 1.2rem;
      height: 4.8rem;
      display: flex;
      align-items: center;
    }
  }
  .logo {
    color: var(--primary-color, $primary-color);
    max-width: 2.4rem;
    max-height: 2.4rem;
  }
  .remove {
    width: 2.4rem;
    color: var(--primary-color, $primary-color);
    padding: 0.4rem;
    margin-right: 1.6rem;
    border-radius: 2.4rem;
    border: 0.1rem solid var(--primary-color, $primary-color);
  }

  @media ($bp-md) {
    &.paddingBottom {
      padding-bottom: 4.8rem;
    }
    .editBtn {
      margin-right: 3.2rem;
    }
  }
}

.school-header {
  padding: 2.4rem;
  @media ($bp-lg) {
    padding: 4.8rem;
  }
  .schoolLogo {
    @media ($bp-lg) {
      width: 7.2rem;
      min-width: 7.2rem;
      height: 7.2rem;
    }
  }
}

.upload-offer {
  border: 0.2rem dashed var(--border-color, $border-color);
  width: 100%;
  height: auto;
  .h4 {
    color: $cap-gray3;
  }
  .file-upload-button {
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    cursor: pointer;
  }
  .upload-limits {
    small {
      color: $cap-gray3;
    }
  }
  &.upload-error-state {
    border: 0.2rem dashed var(--error-color, $error-color);
    @include shake;
  }
}
