.book-consultation {
  position: relative;
  width: 100%;
  background-color: var(--background-color, $background-color);
  .headerSection {
    background-color: transparent;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }
  .logo {
    margin: auto;
    display: block;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 2.8rem;
    color: var(--primary-color, $primary-color);
  }
  .myCap {
    width: 15rem;
    @media (#{$bp-md}) {
      width: 15rem;
    }
  }
  .myCapSuccess {
    width: 100%;
    max-width: 25rem;
    @media (#{$bp-md}) {
      max-width: 30rem;
    }
  }
  .selectLabel {
    @apply flex flex-col;
    width: 100%;
    margin-bottom: 2.4rem;
    span {
      margin-bottom: 0.4rem;
    }
  }
  .primary {
    min-width: 24rem;
  }
}
