.status {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  height: 2rem;
  margin-left: 1rem;
  border-radius: 1rem;
  color: var(--tag-text-color, $tag-text-color);
  &.very-strong {
    background: #5ec185;
  }
  &.strong {
    background: #badeb0;
  }
  &.less-likely {
    background: #eecc9d;
  }
  &.low {
    background: $basic-white;
    border: 0.1rem solid black;
  }
}

.award-details {
  .help-btn {
    min-height: 2.8rem;
    min-width: 2.8rem;
    margin-left: 1.2rem;
  }
}

.appeal-limit-card {
  max-height: 40rem;
}
