.sectionsContainer {
  padding-top: 7.2rem;
  @media ($bp-md) {
    padding-top: 6rem;
  }
  .section {
    background: transparent;
    .arrow {
      display: none;
      width: 4.8rem;
      padding: 1.6rem;
      box-shadow: none;
      margin-bottom: 4.4rem;
      &.left {
        margin-right: -1.2rem;
      }
      &.right {
        margin-left: -1.2rem;
      }
      &:disabled {
        color: var(--disabled-color, $disabled-color);
      }
      @media ($bp-md) {
        display: block;
      }
    }
    .sectionTitle {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      margin-bottom: 2.4rem;
      line-height: 3.2rem;
      @media ($bp-md) {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
      }
    }
    .sectionContainer {
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
        width: 0; /* Remove scrollbar space */
      }
      @media ($bp-md) {
        padding: 0;
      }
      .cardGallery {
        padding-bottom: 4.4rem;
        display: grid;
        grid-auto-flow: column;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        scrollbar-width: none; /* Firefox */
        scroll-padding: 1.2rem;
        &::-webkit-scrollbar {
          display: none;
          -webkit-appearance: none;
          width: 0; /* Remove scrollbar space */
        }
        //On safari, horizontal scrolling ignores end-side padding so we must add after and adjust for padding.
        &::after {
          content: "";
          width: 1.2rem;
          margin-left: -1.2rem;
        }
        @media ($bp-md) {
          grid-auto-columns: 33.33%;
          scroll-snap-type: unset;
          padding-left: 0;
          padding-right: 0;
        }
        @media ($bp-xxl) {
          grid-auto-columns: 25%;
        }
        .cardContainer {
          position: relative;
          width: 82vw;
          max-width: 30.8rem;
          margin-left: 1.2rem;
          margin-right: 1.2rem;
          scroll-snap-align: start;
          @media ($bp-md) {
            width: auto;
            max-width: 100%;
            padding-left: 0;
            scroll-snap-align: unset;
          }
          .card {
            width: 100%;
            min-width: 100%;
          }
        }
        &.full-view {
          //Used for cards that take up the whole gallery view
          grid-auto-columns: 100%;
          .cardContainer {
            width: auto;
            max-width: 100%;
            padding-left: 0;
          }
        }
      }
    }
  }
}
