//
.profileContainer {
  position: relative;
  width: 100%;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  @media ($bp-md) {
    position: initial;
  }
  .pseudo-nav {
    height: 7.2rem;
    // margin-bottom: 3.2rem;
    // @media ($bp-lg) {
    //   margin-bottom: 8rem;
    // }
    &.constrained {
      width: 100%;
      max-width: 60.8rem;
      position: relative;
    }
  }
  .editBtn {
    @media ($bp-md) {
      margin-right: 3.2rem;
    }
  }
  .profileDirectory {
    .profileOption {
      @include h3;
      height: 6.8rem;
      width: 100%;
      max-width: 45rem;
      margin-bottom: 3.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--label-text-color, $label-text-color);
      .arrow {
        color: var(--primary-color, $primary-color);
        width: 4.8rem;
        height: 4.8rem;
        padding: 1.6rem;
        margin-right: -1.6rem;
      }
    }
  }
  .accountInfoContainer {
    border-top: 0.1rem solid var(--border-color, $border-color);
    width: 100%;
    max-width: 45rem;
    li {
      list-style-type: none;
      margin-left: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 6.8rem;
      width: 100%;
      border-bottom: 0.1rem solid var(--border-color, $border-color);
      .accountInfoOption {
        text-align: left;
        small {
          color: var(--label-text-color, $label-text-color);
          margin-right: 2.4rem;
        }
        .email {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &:hover {
            overflow: visible;
            background: var(--background-color, $background-color);
            border-radius: 0.4rem;
            z-index: 50;
          }
        }
      }
    }
  }
}
