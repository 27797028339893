// Typography
//New font for different weights
@font-face {
  font-family: "AvenirNextRegular";
  src: url("/fonts/AvenirNext-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextMedium";
  src: url("/fonts/AvenirNext-Medium.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextDemiBold";
  src: url("/fonts/AvenirNext-DemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextBold";
  src: url("/fonts/AvenirNext-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
//––––––––––––––––––––––––––––––––––––––––––––––––––

body {
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0;
  font-family: "AvenirNextRegular";
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 1.6rem;
  font-weight: 300;
}

h1,
.h1 {
  font-size: 3.2rem;
  line-height: 3.6rem;
  letter-spacing: 0;
  font-family: "AvenirNextBold";
}
h2,
.h2 {
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: 0;
  font-family: "AvenirNextBold";
  &.fontRegular {
    font-family: "AvenirNextRegular";
  }
}
h3,
.h3 {
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: 0;
  font-family: "AvenirNextBold";
}
h4,
.h4 {
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0;
  font-family: "AvenirNextDemiBold";
}
h5,
.h5 {
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0;
  font-family: "AvenirNextRegular";
}
h6,
.h6 {
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0;
  font-family: "AvenirNextDemiBold";
}

// Larger than phablet
@media (#{$bp-md}) {
  h1,
  .h1 {
    // font-size: 5rem;
    // line-height: 1.2em; //temporary line-height until receiving desktop mock up
  }
  h2,
  .h2 {
    // font-size: 4.2rem;
    // line-height: 1.2em;
  }
  h3,
  .h3 {
    // font-size: 3.6rem;
    // line-height: 1.2em;
  }
  h4,
  .h4 {
    // font-size: 3rem;
    // line-height: 1.2em;
  }
  h5,
  .h5 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  h6,
  .h6 {
    // font-size: 1.5rem;
    // line-height: 1.2em;
  }
}

p,
.p {
  margin-top: 0;
  letter-spacing: 0;
  margin-bottom: 1.6rem;
}

small,
.small {
  display: inline-block;
  margin-top: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0;
  font-family: "AvenirNextMedium";
}

.micro {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.tiny {
  font-size: 1rem;
  line-height: 1.2rem;
}

.font-bold {
  font-family: "AvenirNextBold";
}

b,
.font-demi-bold {
  font-family: "AvenirNextDemiBold";
}

.font-medium {
  font-family: "AvenirNextMedium";
}

.font-font-regular {
  font-family: "AvenirNextRegular";
}

.font-italic {
  font-style: italic;
}

ul,
ol {
  li {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-weight: 300;
  }
}

.removeMargin {
  margin-bottom: 0;
}

button {
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: 0.031rem;
  font-family: "AvenirNextDemiBold";
}

input,
select {
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0;
  font-family: "AvenirNextRegular";
  color: var(--input-text-color, $input-text-color);
  background: var(--input-color, $input-color);
  &:disabled {
    background: var(--disabled-input-color, $disabled-input-color);
    -webkit-text-fill-color: var(--disabled-text-color, $disabled-text-color);
    opacity: 1; /* required on iOS */
  }
}

//Labels
label {
  position: relative;
  .labelFocus {
    color: var(--label-text-color, $label-text-color);
  }
  &:focus-within {
    .labelFocus {
      color: var(--body-text-color, $body-text-color);
      font-family: "AvenirNextDemiBold";
    }
  }
}

//Links
a {
  color: var(--primary-color, $primary-color);
}

//Errors
.textErrorState {
  color: var(--error-color, $error-color);
}

.textWarningState {
  color: var(--warning-color, $warning-color);
}

.errorMessage {
  color: var(--error-color, $error-color);
  margin-top: 1.2rem;
}

//Reset to default for nested elements or text
.resetFont {
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0;
  font-family: "AvenirNextRegular";
}
