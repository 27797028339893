.modal-container {
  @apply flex justify-center;
  align-items: center;
  background: rgba(var(--tertiary-color-rgb, $tertiary-color-rgb), 0.8);
  padding: 1.6rem;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  z-index: 100;
  @media ($bp-md) {
    padding-top: 7.2rem;
    align-items: flex-start;
  }
  .modal {
    @apply flex flex-col items-center justify-center text-center;
    width: 100%;
    max-width: 76.6rem;
    max-height: 100%;
    background: var(--background-color, $background-color);
    border-radius: 0.8rem;
    border: 0.1rem solid var(--border-color, $border-color);
    position: relative;
    &.compact {
      max-width: 48rem;
      max-height: 66rem;
    }
    @media ($bp-md) {
      height: auto;
      max-height: 100%;
    }
    .modal-scroll {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
      @media ($bp-md) {
        padding-left: 4rem;
        padding-right: 2.4rem;
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
      .modal-content {
        @apply flex flex-col items-center justify-center text-center;
        .infoText {
          // max-width: 36.6rem;
          max-width: 52rem;
          //TODO make this more universal and 1.5x the fontsize it's being applied to
          &.h5 {
            line-height: 3rem;
          }
        }
        .description {
          padding: 0.8rem;
        }
        .extraMargin {
          margin-bottom: 4.8rem;
        }
        .hideInfoBtn {
          min-height: 4.8rem;
          width: 15.9rem;
          margin-top: 4.8rem;
        }
        .upgradeBtn {
          width: 100%;
          max-width: 27.4rem;
          height: 5.6rem;
          margin-bottom: 3.2rem;
        }
        .noThanksBtn {
          @include h6;
          color: var(--primary-color, $primary-color);
        }
        .closeBtn {
          @include h6;
          color: var(--primary-color, $primary-color);
        }
        .notice {
          .icon {
            width: 5.6rem;
            min-width: 5.6rem;
            height: 5.6rem;
            color: $cap-dark-orange;
          }
        }
        .timer {
        }
        .thank-you {
          border-radius: 0.8rem;
          background-color: var(--background-color, $background-color);
        }
        .close-modal {
          width: 3.6rem;
          padding: 0.7rem;
          position: absolute;
          top: -1.8rem;
          right: -1.8rem;
          background-color: var(--background-color, $background-color);
          // border: 0.1rem solid;
          &:hover {
            @include lightShadow;
            background: var(--button-highlight-color, $button-highlight-color);
            color: var(
              --button-highlight-text-color,
              $button-highlight-text-color
            );
            // border: 0.1rem solid
            //   var(--button-highlight-color, $button-highlight-color);
          }
        }
      }
      .outcomesModal {
        max-width: 47.4rem;
        margin: auto;
        .table {
          width: calc(100% + 1.6rem);
          max-width: 47.4rem;
          height: 18.8rem;
          margin-top: 3.2rem;
          margin-bottom: 1.2rem;
          display: table;
          > * {
            @apply flex flex-row;
            width: 100%;
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            border-bottom: 0.1rem solid var(--border-color, $border-color);
            > * {
              // width: 24%;
              // margin-right: 2rem;
              text-align: left;
            }
            :nth-child(1) {
              // background: pink;
              width: 16%;
            }
            :nth-child(2) {
              width: 30%;
              @media ($bp-sm) {
                width: 34%;
              }
            }
            :nth-child(3) {
              width: 30%;
              @media ($bp-sm) {
                width: 34%;
              }
            }
            :nth-child(4) {
              width: 24%;
              @media ($bp-sm) {
                width: 16%;
              }
            }
            :first-child {
              // width: 16%;
            }
            :last-child {
              // margin: 0;
            }
          }
          > :first-child {
            border: none;
            padding-top: 0;
            padding-bottom: 0.4rem;
          }
          > :last-child {
            border: none;
          }
        }
        .description {
          padding: 0.8rem;
          @media ($bp-md) {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
          }
        }
        .hideOutcomesBtn {
          margin-top: 2.4rem;
          @media ($bp-md) {
            margin-top: 4.8rem;
          }
        }
      }
    }
  }
  .video-modal-content {
    @apply flex flex-col items-center justify-center text-center;
    width: 90vw;
    height: 56.25vw;
    max-width: 40rem;
    max-height: 25rem;
    background: var(--background-color, $background-color);
    border-radius: 0.8rem;
    position: relative;
    @media ($bp-md) and ($bp-vsm) {
      max-width: 90rem;
      max-height: 56.25rem;
    }
    &.efc-video {
      // 1920 / 1080 rounded up
      height: 50.625vw;
      max-height: 22.6rem;
      @media ($bp-md) and ($bp-vsm) {
        max-height: 50.7rem;
      }
    }
    .video-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--background-color, $background-color);
      display: flex;
      border-radius: 0.8rem;
      iframe {
        width: 100%;
        height: 100%;
        border-radius: 0.8rem;
        border: 0.1rem solid var(--background-color, $background-color);
      }
    }
  }
}

.modal-scroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  @media ($bp-md) {
    scrollbar-width: 1.6rem; /* Firefox */
  }
  /* width */
  &::-webkit-scrollbar {
    display: none;
    border-radius: 0.8rem;
    width: 1.6rem;
    @media ($bp-md) {
      display: block;
    }
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--progress-color, $progress-color);
    border-radius: 0.8rem;
    border: 0.3rem solid transparent;
    background-clip: content-box;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--progress-color-rgb, $progress-color-rgb), 0.7);
    border: 0.3rem solid transparent;
    background-clip: content-box;
  }
}
