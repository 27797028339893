#account-edit-modal {
  padding: 2.4rem;
  padding-top: 3.2rem;
  padding-bottom: 4rem;
  width: 100%;
  max-width: 49.8rem;
  @media ($bp-lg) {
    padding-top: 4.8rem;
    padding-bottom: 6.4rem;
  }
  .divider {
    margin-bottom: 2.4rem;
  }
  .inputs {
    width: 100%;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.4rem;
      span {
        margin-bottom: 0.8rem;
      }
    }
  }
  .primary {
    width: 100%;
    max-width: 29.2rem;
    height: 5.6rem;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }
  .checkEmailText {
    margin-top: 3.2rem;
  }
}
