.preApprovalInfoBlock,
.preApprovalInfoEditBlock {
  width: 100%;
  max-width: 60.8rem;
  &.paddingBottom {
    padding-bottom: 3.2rem;
  }
  &.borderBottom,
  .borderBottom {
    border-bottom: 0.1rem solid var(--border-color, $border-color);
  }

  .introText {
    max-width: 41rem;
    margin: auto;
  }

  h2 {
    margin-bottom: 0;
  }
  h3 {
    padding-bottom: 1.6rem;
  }
  .contentItem {
    border-bottom: 0.1rem solid var(--hairline-color, $hairline-color);
  }
  .sum-list-item {
    color: var(--progress-color, $progress-color);
  }

  @media ($bp-md) {
    &.paddingBottom {
      padding-bottom: 4.8rem;
    }
    .editBtn {
      margin-right: 3.2rem;
    }
  }
}

.preApprovalInfoEditBlock {
  margin-top: 3.2rem;
  margin-bottom: 8rem;
  @media ($bp-md) {
    margin-top: 4.8rem;
  }
  h3 {
    padding-bottom: 1.6rem;
    margin-bottom: 3.2rem;
    border-bottom: 0.1rem solid var(--border-color, $border-color);
  }
  .inputItem {
    @apply flex flex-col;
    margin-bottom: 2.4rem;
    .help-btn {
      height: 2.4rem;
      width: 2.4rem;
    }
    .labelWrapper {
      margin-bottom: 0.8rem;
    }
    .sliderText {
      @apply flex justify-between;
      margin-bottom: 1.4rem;
      * {
        margin-bottom: 0;
      }
    }
    .sliderProgressContainer {
      @apply flex items-center;
      height: 2.4rem; //height of thumb
      margin-top: -2.4rem;
      transform: translateY(2.4rem);
      width: 100%;
      .sliderProgressBar {
        @include track;
        background: var(--border-color, $border-color);
        border-radius: 0.5rem;
        overflow: hidden;
        .sliderProgress {
          height: 100%;
          background: var(--primary-color, $primary-color);
        }
      }
    }
    .minMax {
      margin-top: 0.6rem;
    }
  }
}
