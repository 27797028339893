// Margin bottom matching tailwind config redeclaration due to typography mb defaults
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.8rem;
}
.mb-2 {
  margin-bottom: 1.6rem;
}
.mb-3 {
  margin-bottom: 2.4rem;
}
.mb-4 {
  margin-bottom: 3.2rem;
}
.mb-6 {
  margin-bottom: 4.8rem;
}
