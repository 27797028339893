.navContainer {
  @apply relative flex flex-row items-center justify-center text-center;
  width: 100%;
  top: 0;
  height: 7.2rem;
  color: var(--highlight-text-color, $highlight-text-color);
  background-color: var(--mobile-top-nav-color, $mobile-top-nav-color);
  .logo {
    @apply absolute rounded-full items-center justify-center;
    height: 2.8rem;
    width: 2.8rem;
    color: inherit;
    button {
      width: 100%;
      height: 100%;
    }
  }
  .back {
    @apply flex items-center;
    width: 2.4rem;
    height: 2.4rem;
  }
  .hamburger {
    color: var(--hamburger-color, $hamburger-color);
    button {
      width: 5.2rem;
      height: 5rem;
      padding: 1.6rem;
    }
  }
  .upgrade {
    padding: 1.6rem;
    color: var(--primary-color, $primary-color);
  }
  .admin-button {
    @include lightShadow;
    background: var(--error-color, $error-color);
    color: var(--highlight-text-color, $highlight-text-color);
    border: 0.1rem solid var(--error-color, $error-color);
    .logout {
      opacity: 0;
      user-select: none;
    }
    &:hover {
      background: var(--quinary-color, $quinary-color);
      color: var(--error-color, $error-color);
      .user-info {
        opacity: 0;
        user-select: none;
      }
      .logout {
        opacity: 1;
        user-select: all;
      }
    }
  }
  @media ($bp-lg) {
    &.primaryNav {
      @apply items-center;
      background-color: var(--card-color, $card-color);
      // color: var(--primary-color, $primary-color);
      color: var(--top-nav-link-color, $top-nav-link-color);
      padding: 0 4rem;
      .logo {
        left: 2rem;
      }
      ul {
        flex: 3;
      }
      li {
        @apply list-none capitalize cursor-pointer;
        margin: 0 1.6rem;
      }
      .upgrade-button {
        width: 10rem;
        height: 3.2rem;
        margin-right: 3.2rem;
        small {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
      a {
        color: var(--top-nav-link-color, $top-nav-link-color);
      }
      .percent-complete {
        padding-top: 0.4rem;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
      }
    }
  }
}

.secondaryNav {
  @apply flex flex-row;
  position: relative;
  height: 5.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 2.4rem;
  color: var(--quaternary-color, $quaternary-color);
  background-color: var(--secondary-color, $secondary-color);
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0; /* Remove scrollbar space */
  }
  a {
    color: inherit;
  }
  .target {
    width: var(--underlineWidth);
    height: 0.3rem;
    position: absolute;
    background-color: var(--primary-color, $primary-color);
    bottom: 0;
    transition: all 0.2s;
  }
  .dashboardItem {
    white-space: nowrap;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 1rem;
    position: relative;
    transition: color 0.2s;
    span {
      pointer-events: none; //prevent event triggering on span
    }
    .selected {
      color: var(--highlight-text-color, $highlight-text-color);
    }
  }
  .logout-secondary {
    display: none;
    @media ($bp-lg) {
      display: initial;
    }
  }
  @media ($bp-lg) {
    @apply h-auto;
    padding-left: 4.8rem;
    background-color: var(--secondary-color, $secondary-color);
    min-width: 26rem;
    .studentName {
      margin-top: 2.4rem;
      margin-bottom: 7.2rem;
      color: var(--secondary-color-invert, $secondary-color-invert);
    }
    .dashboardItem {
      margin-bottom: 2.4rem;
      margin-top: 2.4rem;
      padding-left: 0;
      width: auto;
      .selected {
        border-bottom-width: 0.3rem;
        border-bottom-color: var(--primary-color, $primary-color);
      }
    }
  }
}

.onboardingNav {
  color: var(--primary-color, $primary-color);
  background-color: var(--background-color, $background-color);
  .leftBtn {
    width: 5.6rem;
    height: 5.6rem;
    padding: 1.6rem;
  }
  .save {
    width: 11.2rem;
    padding: 1.6rem;
    margin-bottom: 0;
    color: var(--primary-color, $primary-color);
    &:disabled {
      color: var(--disabled-color, $disabled-color);
    }
  }
}
