.create-account-form {
  .accountContainer {
    .accountText {
      margin-top: 1.6rem;
      h5 {
        min-height: 7.2rem;
      }
    }
    .terms {
      padding-top: 1.6rem;
    }
  }
}
