//Specialized Onboarding Styles

.studentInfoContainer {
  .studentStart {
    padding-top: 5.6rem;
    @media ($bp-md) {
      padding-top: 0;
    }
  }
  .studentInfoText {
    h5 {
      margin-bottom: 3.2rem;
    }
  }
  .sibling {
    min-width: calc(50% - 0.6rem);
  }
  .testOptional {
    margin-top: 0.8rem;
    margin-bottom: 2.4rem;
  }
}
