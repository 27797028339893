.form-modal-container {
  background: rgba(var(--tertiary-color-rgb, $tertiary-color-rgb), 0.8);
  z-index: 100;
  .modal {
    @apply flex flex-col items-center justify-center text-center;
    width: 100%;
    max-width: 92.4rem;
    height: calc(100% - 7.2rem);
    max-height: calc(100% - 7.2rem);
    top: 7.2rem;
    background: var(--card-color, $card-color);
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    border: 0.1rem solid var(--border-color, $border-color);
    overflow: hidden;
    position: relative;
    @media ($bp-lg) {
      height: auto;
      max-height: calc(100% - 11.2rem);
      border-radius: 0.8rem;
    }
    .modal-header {
      @apply flex flex-row items-center
        justify-between;
      width: 100%;
      height: 5.2rem;
      min-height: 5.2rem;
      background-color: var(--contrast-color2, $contrast-color2);
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      border-bottom: 0.1rem solid var(--hairline-color, $hairline-color);
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      overflow: hidden;
      z-index: 50;
      @media ($bp-md) {
        height: 7.2rem;
        min-height: 7.2rem;
      }
    }
    .modal-content {
      width: 100%;
      &.form-content {
        max-width: 46.6rem;
      }
      .content-section {
        margin-bottom: 3rem;
      }
      .field {
        margin-bottom: 2.4rem;
      }

      .toggle {
        margin-left: 1.6rem;
        div {
          padding: 0.4rem;
          position: absolute;
          top: 0.2rem;
          transition: opacity 0.4s;
        }
        .yes {
          left: 1rem;
          opacity: 0;
        }
        .no {
          right: 1rem;
          opacity: 1;
        }
        input:checked ~ .slider {
          .yes {
            opacity: 1;
          }
          .no {
            opacity: 0;
          }
        }
      }
    }
    .schoolLogo {
      min-width: 5.6rem;
      width: 5.6rem;
      height: 5.6rem;
    }
    .university-name {
      margin-bottom: 0;
      margin-left: 1.2rem;
    }
    .save {
      color: var(--primary-color, $primary-color);
      &:disabled {
        color: var(--disabled-color, $disabled-color);
      }
    }
  }
}
