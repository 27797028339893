.nav {
  height: 7.2rem;
  background: var(--secondary-color, $secondary-color);
  color: var(--highlight-text-color, $highlight-text-color);
  padding: 1.6rem;
  left: 2rem;
  @media ($bp-md) {
    padding: 1.6rem;
    left: 0rem;
  }
  .emptySpace {
    width: 2.4rem;
  }
}
.subNav {
  display:none;
  @media ($bp-md) {
    display: block;
  }
}
.CAPSearchContainer {
  width:100%;
  @media ($bp-md) {
    margin-right: 4.8rem;
    max-width: 110rem;
    min-width: 89rem;
    z-index: 1;
    height: 83%;
    position: absolute;
    top: 13rem;
  }
  .outerSearchBox {
    @media ($bp-md) {
      height: 92%;
    }
  }
  .formContainer {
    width:100%;
    z-index: 10;
    .select-icon {
      // bottom: 0.8rem;
      top: 2.8rem;
    }
    select {
      height: 30px;
      font-size: 1.4rem;
    }

    .select-checkbox {
      margin-top: 0.5rem;
      display: flex;
      gap: 1rem;
      align-items: flex-start;

      span {
        color: var(--input-text-color);
      }
    }

    .mapBar {
      @media ($bp-md) {
        width:100%;
        position: absolute;
        left: 15rem;
        top:5remm;
      }
    }
    .description {
      width: 100%;
      padding-top: 2.4rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 3.2rem;
      color: var(--secondary-color-invert, $secondary-color-invert);
      @media ($bp-lg) {
        padding-top: 4.8rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-bottom: 2.8rem;
      }
      span {
        width: 100%;
        max-width: 29.4rem;
      }
    }
    .filterForm {
      background: var(--background-color, $background-color);
      border-radius: 0.8rem 0.8rem 0 0;
      border: 0.1rem solid var(--border-color, $border-color);
      display: flex;
      @media ($bp-lg) {
        // margin-left: 4.8rem;
        // margin-right: 4.8rem;
        border-radius: 0.8rem;
        width: 100%;
        overflow: hidden;
      }
      .filters {
        // padding: 2.4rem;
        display: flex;
        .selectLabel {
          color: black;
          width: 94%;
          @apply flex flex-col;
          margin-bottom: 1.3rem;
        }
        .selectContainer {
          margin: 1.5rem;
        }
      }
      .advisorContainer {
        background-color: rgb(12, 34, 64);
        margin-right: 2.5rem;
        width: 100%;
        @media ($bp-md) {
          margin-right:0rem;
        }
      }
    }
  }

  .lockupContainer1 {
    padding-top: 1.5rem;
    padding-bottom: 1.2rem;
    button {
      height: 3.8rem;
      width: 85%;
      background-color: rgb(135, 197, 127);
    }
    @media ($bp-md) {
      min-width: 10.9rem;
      padding-top: 1.2rem;
    }

    div.m-auto {
      visibility: hidden;
    }
  }
  .resetContainer {
    padding-top: 0;
    padding-bottom: 0;
    button {
      height: 3.8rem;
      width: 85%;
      font-size: 1.6rem
    }
    @media ($bp-md) {
      min-width: 10.9rem;
      // padding-top: 1.2rem;
    }
  }

  .addSchools {
    padding: 1.6rem;
    position: relative;
    width: 68%;
    left: -1.8rem;
    top: 0.6rem;
    z-index: 25;
    @media ($bp-md) {
      width: 100%;
      left: 0rem;
    }
    button {
      height: 2.8rem;
      position: absolute;
      width: 50%;
      right: 1rem;
      font-size: 14px;
      @media ($bp-md) {
        height: 3.8rem;
        width: 11.1rem;
        top: 0rem;
      }
    }
  }
  .advancedList {
    background: var(--secondary-color, $secondary-color);
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 1.6rem;
    .results-container {
      max-width: 92.4rem;
    }
    .mapBar {
      display: flex;
      @media ($bp-md) {
        width: 100%;
        position: relative;
      }
    }
    .results-sort {
      padding: 2.4rem 0;
      color: var(--secondary-color-invert, $secondary-color-invert);
      max-width: 92.4rem;
      width:28%;
      z-index: 50;
      @media ($bp-lg) {
        padding: 1.6rem 0;
        width:13%;
      }
      small {
        color: var(--label-text-color, $label-text-color);
      }
    }
    .filter-container {
      @include darkShadow;
      max-width: 38rem;
      background: var(--card-color, $card-color);
      color: var(--body-text-color, $body-text-color);
      margin-bottom: 4.8rem;
      border-radius: 0.8rem;
      transform: translateY(6rem);
      @media ($bp-lg) {
        max-width: 64rem;
        width:70%;
      }
    }
    .curatedGallery {
      z-index: 15;
      max-width: 92.4rem;
    }
    .cardContainer {
      margin-bottom: 1.8rem;
    }
  }

  .results-filter {
    padding: 2.4rem 0;
    color: var(--secondary-color-invert, $secondary-color-invert);
    max-width: 92.4rem;
    width: 33%;
    z-index: 10;
    @media ($bp-lg) {
      padding: 1.6rem 0;
      width:70%;
      display:none;
    }
    small {
      color: var(--label-text-color, $label-text-color);
    }
  }
  .searchResultContainer {
    height: 100%;
    padding-bottom: 3rem;
    padding-top: 0.6rem;
    // position: absolute;
    top: 11.8rem;
    @media ($bp-md) {
      height: calc(100vh - 328px);
      width: 100%;
      padding-top: 0rem;
      top: 0rem;
    }
    @media ($bp-xxxl) {
      height: 54rem;
      width: 100%;
    }
    @media ($bp-xxxxl) {
      height: 50rem;
      width: 100%;
    }
  }

  .selectContainer div.wrapper {
    height: 100%;

    @media ($bp-md) {
      height: calc(100vh - 418px);
      overflow: auto;
      width: 90%;
    }
    @media ($bp-xxxl) {
      height: 54rem;
      overflow: auto;
      width: 90%;
    }
    @media ($bp-xxxxl) {
      height: 50rem;
      overflow: auto;
      width: 90%;
    }

    // Details and summary tags
    details {
      summary {
        font-weight: bold;
        margin-bottom: 0.5em;
      }

      summary::marker {
        color: $cap-blue;
      }
    }

    details[open] {
      summary {
      }
    }

    // Style selected checkboxes
    select:has(> option[value="null"]:checked), select:has(> option[value="0"]:checked), select:has(> option[value=""]:checked) {
      background: white;
    }

    select {
      background: #ff9;
    }

  }

  .loadMoreContainer {
    @apply rounded-lg flex items-center justify-center;
    background-color: var(--card-color, $card-color);
    width: 94.5%;
    max-width: 92.4rem;
    // margin: -1.6rem auto 4rem auto;
    margin: 0 0 4rem 16px;
    padding: 1rem;
    border: 0.1rem solid var(--border-color, $border-color);
    top: 96rem;
    // position: relative;
    @media ($bp-xl) {
      top: 63.25rem;
      left: -0.5rem;
    }
    @media ($bp-xxl) {
      top: 63.5rem;
    }
    @media ($bp-xxxl) {
      top: 56.5rem;
    }
    @media ($bp-xxxxl) {
      top: 52.5rem;
    }
  }
}
