.sidebarContainer {
  position: fixed;
  inset: 0 0 0 0;
  pointer-events: none;
  z-index: 99; //1 less than modal-container
}

.sidebarBG {
  background: $basic-black;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}

.showSidebarBG {
  opacity: 0.5;
  pointer-events: auto;
}

.sidebar {
  background: var(--primary-color, $primary-color);
  color: var(--quinary-color, $quinary-color);
  width: 50%;
  height: 100%;
  top: 0;
  left: -50%;
  position: fixed;
  transition: all 0.25s;
  pointer-events: auto;
  .sidebarScroll {
    width: 100%;
    height: 100%;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      -webkit-appearance: none;
      width: 0; /* Remove scrollbar space */
    }
  }
  .closeBtn {
    color: var(--quinary-color, $quinary-color);
    width: 7.2rem;
    height: 7.2rem;
    padding: 2.4rem;
  }
  .sidebarOption {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    color: inherit;
    display: block;
    .icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 0.8rem;
    }
    &:hover {
      background: var(--secondary-color, $secondary-color);
      color: var(--highlight-text-color, $highlight-text-color);
    }
  }
  .logoutContainer {
    margin: 2.4rem;
    margin-bottom: 3.2rem;
    .logoutBtn {
      width: 100%;
      padding: 0.5rem;
    }
  }
}

.showSidebar {
  // left: 50%;
  transform: translateX(100%);
}
