.talkToAdvisor {
  background: var(--secondary-color, $secondary-color);
  color: var(--secondary-color-invert, $secondary-color-invert);
  z-index: 50;

  @media ($bp-md) {
    border-radius: 0.8rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .textContent {
    background: var(--secondary-color, $secondary-color);
    max-width: 100%;
    position: absolute;
    padding-left: 2.4rem;

    // left: 32rem;
    h3 {
      width: 100%;

      @media ($bp-md) {
        margin-bottom: 0.8rem;
        white-space: nowrap;
      }
    }

    span {
      margin-bottom: 2.4rem;

      @media ($bp-md) {
        @include bodyBig;
        margin-bottom: 0;
      }
    }
  }

  button {
    position: absolute;
    right: 7rem;

    &.highlight {
      &:hover {
        color: var(--tertiary-color, $tertiary-color);
        background: var(--secondary-color-invert, $secondary-color-invert);
        border-radius: 0.8rem;

      }
    }

    >* {
      padding: 0.6rem;
    }
  }
}