//Affordability and apply for aid have near identical layout. TODO: What should the file be renamed to?
.offers-container,
.apply-for-aid-container,
.affordability-container,
.how-to-pay-container {
  padding-top: 0;
  position: relative;
  .background {
    width: 100%;
    height: 12.8rem;
    background: var(--secondary-color, $secondary-color);
    position: absolute;
    z-index: -1;
    @media ($bp-lg) {
      height: 24rem;
      background: var(--tertiary-color, $tertiary-color);
    }
  }
  .desktop-header {
    color: var(--secondary-color-invert, $secondary-color-invert);
    @media ($bp-lg) {
      background-color: var(--tertiary-color, $tertiary-color);
      margin-bottom: 0;
      padding-bottom: 1.6rem;
    }
    text-align: center;
    padding-top: 4.8rem;
  }
  .header-text {
    height: 10.8rem;
    min-height: 10.8rem;
    color: var(--secondary-color-invert, $secondary-color-invert);
    padding-top: 2.4rem;
    @media ($bp-lg) {
      @include bodyBig;
      height: 9.6rem;
      min-height: 9.6rem;
      padding-top: 0;
    }
    @media ($bp-lg) {
      background-color: var(--tertiary-color, $tertiary-color);
    }
  }
  .section {
    // margin-bottom: 4.8rem;
  }
}

.apply-for-aid-container {
  .header-text {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
  .card {
    .early-action {
      margin-top: 2.4rem;
      small {
        color: var(--label-text-color, $label-text-color);
        margin-bottom: 0.4rem;
      }
      .progress-bar {
        height: 0.8rem;
        background: var(--border-color, $border-color);
        margin-bottom: 2.4rem;
        .progress {
          height: 100%;
          background: var(--primary-color, $primary-color);
        }
      }
    }
    .right-side {
      padding-left: 1.2rem;
    }
    .application-required {
      > * {
        margin-bottom: 1.6rem;
      }
      small {
        color: var(--label-text-color, $label-text-color);
      }
      .title {
        color: var(--body-text-color, $body-text-color);
        margin-bottom: 1.8rem;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .terms {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-bottom: 4.8rem;
    max-width: 64rem;
    > * {
      margin-bottom: 2.4rem;
    }
    b {
      margin-bottom: 0.8rem;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}

.offers-container,
.affordability-container,
.how-to-pay-container {
  .header-text {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .card {
    .affordability-costs {
      small {
        white-space: nowrap;
        color: var(--label-text-color, $label-text-color);
        margin-bottom: 0.4rem;
      }
      .review-status {
        color: var(--primary-color, $primary-color);
      }
    }
    .right-side {
      padding-left: 3.2rem;
    }
    .estimates {
      > * {
        margin-bottom: 1.6rem;
      }
      small {
        color: var(--label-text-color, $label-text-color);
        margin-bottom: 0.4rem;
      }
      .title {
        color: var(--body-text-color, $body-text-color);
        margin-bottom: 1rem;
      }
      .year-one-loans {
        @include colorRed;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  .affordability-profile {
    margin-bottom: 4.8rem;
    border-bottom: 0.1rem solid var(--border-color, $border-color);
    @media ($bp-md) {
      margin-left: 4.8rem;
      margin-right: 4.8rem;
    }
    h2 {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      @media ($bp-md) {
        @include h3;
        padding: 0;
        margin-bottom: 2rem;
      }
    }
    .rating-row {
      @apply flex justify-between items-center;
      padding: 1.6rem;
      height: 6.8rem;
      > * {
        width: auto;
      }
      b {
        width: 60%;
      }
    }
    .rating-row:nth-child(even) {
      background: var(--contrast-color, $contrast-color);
    }
  }
}
