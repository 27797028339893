@use 'sass:math';

// Variables

//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-xs: "min-width: 375px" !default;
$bp-sm: "min-width: 512px" !default;
$bp-md: "min-width: 768px" !default;
$bp-lg: "min-width: 1024px" !default;
$bp-xl: "min-width: 1280px" !default;
$bp-xxl: "min-width: 1440px" !default;
$bp-xxxl: "min-width: 2560px" !default;
$bp-xxxxl: "min-width: 3440px" !default;
$bp-vxs: "min-height: 375px" !default;
$bp-vsm: "min-height: 512px" !default;
$bp-vmd: "min-height: 768px" !default;
$bp-landscape: "orientation: landscape" !default;
$bp-portrait: "orientation: portrait" !default;

//--------------------------------------------------------------

// Typography
$font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;

//Grid Variables
$container-width: 960px !default;
$container-width-larger-than-mobile: 85% !default;
$container-width-larger-than-phablet: 80% !default;
$total-columns: 12 !default;
$column-width: math.div(
  100,
  $total-columns
) !default; // calculates individual column width based off of # of columns
$column-margin: 4% !default; // space between columns

// Misc
$global-radius: 4px !default;
$mix-mode: multiply !default;

//hexes are easy to read, but need to be converted to rgb in order to use the sass rgba mixin for reducing opacity.
@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

//Colors
$basic-white: #ffffff !default;
$cap-off-white: #f6f6f6 !default;
$cap-gray1: #e2e5e8 !default;
$cap-gray2: #bfc4cd !default;
$cap-gray3: #adaeae !default;
$cap-gray4: #8c8c8c !default; //or #747676
$cap-gray5: #565858 !default;
$cap-gray6: #414040 !default;
$cap-gray7: #333333 !default; //or #353535
$basic-black: #000000 !default;
$cap-blue: #0467f1 !default;
$cap-bright-blue: #60a0d7 !default;
$cap-navy: #0c2240;
$cap-midnight: #00132d !default;
//Tag Colors
$cap-tag-blue: #2f5993 !default;
//Other Colors
$cap-dark-orange: #fa8d00 !default;
$cap-yellow: #f8e695 !default;
$cap-bright-green: #23c57f !default;
//Status Colors - used for affordabiliy status
$cap-green: #b0e0ab !default;
$cap-orange: #f4cb96 !default;
$cap-light-red: #e78e8b !default;
$cap-red: #cf3f42 !default;

//Theme colors
$primary-color: $cap-blue !default;
$primary-color-highlight: $cap-bright-blue !default; //used for primary button gradient
$secondary-color: $cap-navy !default;
$secondary-color-invert: $cap-off-white !default;
$tertiary-color: $cap-midnight !default;
$tertiary-color-rgb: #{hexToRGB($tertiary-color)}; //rgb needed for transparency backgrounds
$quaternary-color: $cap-gray3 !default; //used for certain prominent text
$quinary-color: $basic-white !default; //used for defining the extremes of the theme, generally pure white or black
//Background colors
$background-color: $cap-off-white !default;
$contrast-color: $basic-white !default; //used for giving contrast to backgrounds such as for separating rows
$contrast-color2: $cap-off-white !default; //used for giving contrast to backgrounds such as for separating rows
//Specific use case colors
$error-color: $cap-red !default;
$warning-color: $cap-dark-orange !default;
$border-color: $cap-gray2 !default;
$hairline-color: $cap-gray1 !default; //used for thin borders and dividers
$upgrade-button-color: $cap-bright-green !default;
$recommended-tag-color: $cap-bright-green !default;
$card-color: $basic-white !default; //used for background of typical cards
$disabled-color: $cap-gray2 !default; //used for buttons and elements that should look "greyed out"
$progress-color: $cap-gray4 !default; //used for progress bars, scrollbars, and results indicators
$progress-color-rgb: #{hexToRGB($progress-color)} !default;
$button-highlight-color: $secondary-color !default; //used when hovering buttons
$button-text-color: $basic-white !default;
$button-highlight-text-color: $basic-white !default;
$mobile-top-nav-color: $cap-navy !default;
$hamburger-color: $basic-white !default;
$dots-color: $cap-blue !default;
$dots-hover-color: $cap-off-white !default;
//font colors
$body-text-color: $cap-gray6 !default;
$label-text-color: $cap-gray5 !default; //used for labels
$highlight-text-color: $basic-white !default; //used for active text or text on dark background
$tag-text-color: $cap-gray6 !default; //affordability status text
$top-nav-link-color: $primary-color !default; //used for primary nav links
//Inputs
$input-color: $basic-white;
$input-text-color: $cap-gray7 !default;
$disabled-input-color: $cap-off-white !default;
$disabled-text-color: $cap-gray3 !default;
// $primary-color-bright: #408efc !default;
//Shadows
$shadow1: rgb(0, 0, 0, 0.2) !default;
$shadow2: rgb(0, 0, 0, 0.03) !default;

@mixin defaultTheme {
  //Define css vars after scss vars to have option to use scss vars as single source of truth
  --basic-white: #{$basic-white}; //not part of whitelabel vars
  //changeable colors from theme
  --primary-color: #{$primary-color};
  --primary-color-highlight: #{$primary-color-highlight};
  --secondary-color: #{$secondary-color};
  --secondary-color-invert: #{$secondary-color-invert}; //$cap-off-white
  --tertiary-color: #{$tertiary-color};
  --tertiary-color-rgb: #{$tertiary-color-rgb};
  --quaternary-color: #{$quaternary-color};
  --quinary-color: #{$quinary-color}; //pure white
  //Background colors
  --background-color: #{$background-color};
  --contrast-color: #{$contrast-color};
  --contrast-color2: #{$contrast-color2};
  //specific use case colors
  --error-color: #{$error-color}; //not part of whitelabel vars
  --warning-color: #{$warning-color}; //not part of whitelabel vars
  --hairline-color: #{$hairline-color};
  --border-color: #{$border-color};
  --upgrade-button-color: #{$upgrade-button-color};
  --recommended-tag-color: #{$recommended-tag-color};
  --card-color: #{$card-color};
  --disabled-color: #{$disabled-color};
  --progress-color: #{$progress-color};
  --progress-color-rgb: #{$progress-color-rgb};
  --button-highlight-color: #{$button-highlight-color};
  --mobile-top-nav-color: #{$mobile-top-nav-color};
  --hamburger-color: #{$hamburger-color};
  --dots-color: #{$dots-color};
  --dots-hover-color: #{$dots-hover-color};
  //font colors
  --button-text-color: #{$button-text-color};
  --button-highlight-text-color: #{$button-highlight-text-color};
  --body-text-color: #{$body-text-color};
  --label-text-color: #{$label-text-color};
  --highlight-text-color: #{$highlight-text-color};
  --tag-text-color: #{$tag-text-color};
  --top-nav-link-color: #{$top-nav-link-color};
  //input colors
  --input-color: #{$input-color};
  --input-text-color: #{$input-text-color};
  --disabled-input-color: #{$disabled-input-color};
  --disabled-text-color: #{$disabled-text-color};
  //Other css properties
  --mix-mode: #{$mix-mode};
}

@mixin testingTheme {
  //changeable colors from theme
  --primary-color: #26276e; //HFCN
  --primary-color-highlight: #0086cd; //
  --secondary-color: #0086cd; //HFCN
  --secondary-color-invert: #f6f6f6; //$cap-off-white
  --tertiary-color: #212224; //HFCN +
  --tertiary-color-rgb: #{hexToRGB(#212224)}; //HFCN +
  --quaternary-color: #ffffff; //HFCN
  --quinary-color: #ffffff; //
  //Background colors
  --background-color: #f6f6f6; //
  --contrast-color: #ffffff; //
  --contrast-color2: #f6f6f6; //
  //specific use case colors
  --hairline-color: #e2e5e8; //
  --border-color: #bfc4cd; //
  --upgrade-button-color: #26276e; //HFCN
  --recommended-tag-color: #2f5993; //
  --card-color: #ffffff; //
  --disabled-color: #bfc4cd; //
  --progress-color: #8c8c8c; //
  --progress-color-rgb: 140, 140, 140; //
  --button-highlight-color: #0086cd; //HFCN
  --mobile-top-nav-color: #e2e5e8;
  --hamburger-color: #26276e;
  --dots-color: #f6f6f6;
  --dots-hover-color: #0086cd;
  //font colors
  --button-text-color: #ffffff; //
  --button-highlight-text-color: #ffffff; //
  --body-text-color: #414040; //
  --label-text-color: #565858; //
  --highlight-text-color: #ffffff; //
  --tag-text-color: #414040; //
  --top-nav-link-color: #0086cd; //HFCN
  //input colors
  --input-color: #ffffff; //
  --input-text-color: #333333; //
  --disabled-input-color: #f6f6f6; //
  --disabled-text-color: #adaeae; //
  //Other css properties
  --mix-mode: unset;
}

/**
 * By leaving :root undefined and setting sass var defaults for every usage of css vars (eg. var(--primary-color, $primary-color)),
 * we can use an external stylesheet (imported from s3) in <Head> that defines variables in :root.
 * NOTE: Comment this in for ease of testing new themes.
 */
// :root {
//   @include testingTheme;
// }
