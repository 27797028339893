.homepage {
  // background-color: green;
  // position: absolute;
}

// make all classes begining with cap-scholarship- to have a white background
[class^="cap-scholarship-"] {
  background-color: white;
}

.cap-scholarship_name {
  display: flex;
  width: 100%;
}