#school-card-edit-modal {
  padding: 2.4rem;
  padding-top: 3.1rem;
  padding-bottom: 4rem;
  margin-top: 5.2rem;
  .remove_application {
    border-top: 0.1rem solid #bfc4cd;
    border-bottom: 0.1rem solid #bfc4cd;
    height: 8rem;
    @apply flex items-center justify-between;
  }
  .field {
    label {
      margin-bottom: 0.75rem;
    }
  }
}
