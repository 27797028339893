.drawer {
  .dropdown {
    border-top: 1px solid var(--border-color, $border-color);
    cursor: pointer;
    .icon {
      width: 1.6rem;
      color: var(--primary-color, $primary-color);
    }
  }
  .drawerRow {
    @apply flex justify-between items-center;
    padding: 1.6rem;
    height: 6.8rem;
    @media ($bp-lg) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
    > * {
      width: auto;
    }
    b {
      // Removed when the HelpButton was added to the "Total Need Met %" item
      // width: 60%;
    }

    // Position the HelpButton to the right of the <b> text
    button {
      float: right;
      margin-left: 0.25em;
      position: relative;
      top: -0.25em;
    }

    span {
      text-align: right;
    }
  }
  .collapsible {
    .accordion {
      b {
        color: var(--label-text-color, $label-text-color);
      }
      .drawerRow:nth-child(odd) {
        background: var(--contrast-color, $contrast-color);
      }
      .asterisk {
        display: block;
        padding-left: 1.6rem;
      }
    }
  }
  .footnote {
    padding-left: 1.6rem;
    color: var(--label-text-color, $label-text-color);
  }
}

.collapsible {
  overflow: hidden;
  transition: all 0.35s ease;
}

.status {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  height: 2rem;
  margin-left: 1rem;
  border-radius: 1rem;
  color: var(--tag-text-color, $tag-text-color);
  &.ideal {
    background: $cap-green;
  }
  &.great {
    background: $cap-green;
  }
  &.good {
    background: $cap-orange;
  }
  &.caution {
    background: $cap-light-red;
  }
}

//Common components
.dropdownBtn {
  .icon {
    width: 1.6rem;
    color: var(--primary-color, $primary-color);
  }
}

.back-btn {
  color: var(--primary-color, $primary-color);
  width: 5.6rem;
  height: 5.6rem;
  padding: 1.6rem;
  margin-top: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  &.dark-btn {
    color: var(--highlight-text-color, $highlight-text-color);
  }
  @media ($bp-md) {
    margin-left: 3.2rem;
  }
}

.radial-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  svg {
    position: absolute;
  }
  svg path {
    will-change: auto;
    stroke-width: 1rem;
    stroke-linecap: round;
    stroke-miterlimit: round;
    &.animate {
      transition: stroke-dashoffset 1000ms ease-in-out;
    }
    &.bg {
      stroke: var(--border-color, $border-color);
    }
    &.meter {
      stroke: var(--primary-color, $primary-color);
    }
  }
}

.pill-progress {
  // background: $cap-green;
  // background: linear-gradient(rgba(176, 224, 171, 1), rgba(176, 224, 171, 1)),
  //   linear-gradient(rgba(248, 230, 149, 1), rgba(248, 230, 149, 1)),
  //   linear-gradient(rgba(231, 142, 139, 1), rgba(231, 142, 139, 1));
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  height: 2.4rem;
  border-radius: 2rem;
  color: var(--tag-text-color, $tag-text-color);
}

//Social login items
.google-btn {
  // margin-top: 2.4rem;
  span {
    @include h6;
  }
}

.schoolLogo {
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
  background: var(--basic-white, $basic-white);
  &.noLogo {
    background: var(--border-color, $border-color);
  }
  .bg {
    width: 50%;
    height: 50%;
    background: var(--secondary-color, $secondary-color);
    border-radius: 2rem;
  }
}
