.colleges {
  max-width: 66.5rem;
  min-width: 20rem;
  width: 100%;
}

//Radio button styles
.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input~.radioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked~.radioCheckmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked~.radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radioCheckmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.wake_me {
  background-color: rgb(0, 19, 45);
  padding: 5rem 0rem;

  .IntakeFormContainer {
    background-color: white;
    width: 90%;
    border-radius: 1.5rem;
    ;
  }

  button[type="submit"] {
    border-radius: .5rem;
    width: 16rem;
    height: 4.5rem;
    font-size: 3rem;
    background-color: rgb(42, 196, 131);
    color: white;

    & :hover {
      background-color: rgb(0, 19, 45);
    }
  }

  //Default styles for text, password and number inputs
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"] {
    @include lightShadow;
    height: 3.0rem;
    border: 0.1rem solid var(--border-color, $border-color);
    border-radius: 0.5rem;
    text-indent: 1rem;

    &.showSpinner {

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: auto;
        /* Chrome, Safari, Edge, Opera */
        margin: 0;
      }
    }

    &.signature {
      height: 4rem;
      font-size: 2.2rem;
    }

    &:focus {
      outline: none;
    }

    &.inputErrorState {
      border: 0.2rem solid var(--error-color, $error-color);
    }

    &:disabled {
      color: var(--disabled-text-color, $disabled-text-color);
    }
  }

  /* The container */
  .chkbxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    height: 32px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: solid 1px black;
  }

  /* Hide the browser's default checkbox */
  .chkbxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 35px;
    background-color: #eee;
    ;
  }

  /* On mouse-over, add a grey background color */
  .chkbxContainer:hover input~.checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .chkbxContainer input:checked~.checkmark {
    background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .chkbxContainer input:checked~.checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .chkbxContainer .checkmark:after {
    left: 13px;
    top: 2px;
    width: 10px;
    height: 20px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  select {
    -webkit-appearance: auto;
    margin: 0;
  }

  fieldset {
    border: 1px solid rgb(93, 166, 241);
    border-radius: 1rem;
    width: 81%;
    padding-bottom: 1rem;
    margin-left: 4rem;
    background-color: #d1f6fa;
  }

  legend {
    font-size: 1.8rem;
    font-weight: bolder;
    font-family: AvenirNextRegular;
    color: rgb(0, 19, 45);
    margin-bottom: 1rem;
    margin-left: 3.5rem;
  }

  .signatureLabel {
    font-size: 2.2rem;
    margin-left: 22.4rem;
    margin-right: 1rem;
  }

  .radioLabel {
    margin-right: 1rem;
  }

  .textLabel {
    font-size: 1.8rem;
    font-weight: 800;
    font-family: AvenirNextRegular;
    color: rgb(133, 187, 216);
    margin-right: 2rem;
    margin-bottom: 1rem;

    &.signature {
      display: block;
    }

    span {
      margin-bottom: .3rem;
    }
  }

  .year {
    width: 9rem;
  }

  .marital_status {
    margin-left: 1.3rem;
    width: 35rem;
    height: 5.4rem;
  }

  .state {
    width: 18rem;
    height: 5.4rem;
  }

  .zipcode {
    width: 11rem;
  }

  .time_zone {
    width: 23rem;
    height: 5.4rem;
  }

  .scores {
    width: 8rem;
    margin-right: 1.3rem;
  }


  .dates {
    width: 16rem;
    margin-right: 1.3rem;
  }

  .desk-logo {
    margin-left: -11.5rem;
    margin-top: 0.5rem;
    height: 6.5rem;
    min-width: 17.8rem;
    cursor: pointer;
    margin-right: 15rem;
    margin-bottom: .5rem;
  }

  .header {
    margin-left: 2.8rem;
    margin-bottom: 2.5rem;
    width: 95%;
  }

  .title {
    font-size: 2.8rem;
    font-weight: 800;
    font-family: AvenirNextBold;
    color: rgb(0, 19, 45);
  }

  .section-container {
    width: 100%;
    background-color: white;
    padding: 0rem 5rem;
  }

  .section-title {
    font-size: 2.0rem;
    font-weight: 800;
    font-family: AvenirNextBold;
    color: rgb(0, 19, 45);
    background-color: rgb(158, 226, 246);
    border-radius: 1.5rem 1.5rem 0 0;
    margin-left: 5.8rem;
    margin-top: 2.8rem;
    padding: .5rem 2rem .5rem 2rem;
    width: 16.5rem;

    &.wide {
      width: 36.5rem;
    }
  }

  .section-content {
    margin-top: 1.3rem;
    margin-left: 6.8rem;
    width: 95%;
  }

  .subSection {
    margin-right: 2.3rem;
  }

  .section-content-text {
    margin-left: -8rem;

    &.multiSection {
      margin-left: 5rem;
    }
  }

  .section {
    width: 93%;
    height: 100%;
    background: #f9f9f9;
    border-left: solid 1px rgb(93, 166, 241);
    border-right: solid 1px rgb(93, 166, 241);

    &.topSection {
      border-top: solid 1px rgb(93, 166, 241);
      border-radius: 1rem 1rem 0 0;
    }
  }

  .subButton {
    padding: 3rem 0rem 5rem 0rem;
    margin-bottom: 3rem;
    background: #f9f9f9;
    color: var(--body-text-color, #414040);
    border-left: solid 1px rgb(93, 166, 241);
    border-right: solid 1px rgb(93, 166, 241);
    border-bottom: solid 1px rgb(93, 166, 241);
    border-radius: 0 0 1rem 1rem;
  }

  .acknowledgeText {
    font-size: 1.6rem;
    font-weight: 800;
    margin-left: -9rem;
    margin-top: -1.2rem;
  }
}